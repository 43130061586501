// InvoiceDetails.js
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axiosInstance from "../axiosConfig";
import config from "../config";
import GenericMetadata from "./GenericMetadata";
import "./InvoiceDetails.css"; // Import CSS

function InvoiceDetails() {
  const { invoiceId } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [showInvoiceMetadata, setShowInvoiceMetadata] = useState(false);
  const [showTransactionMetadata, setShowTransactionMetadata] = useState(false);
  const [transactionMetadata, setTransactionMetadata] = useState(null);

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await axiosInstance.get(
          `${config.API_URL}/invoices/${invoiceId}`
        );
        setInvoice(response.data);
      } catch (error) {
        console.error("Error fetching invoice details:", error);
      }
    };

    const fetchTransactions = async () => {
      try {
        const response = await axiosInstance.get(
          `${config.API_URL}/invoices/${invoiceId}/transactions`
        );
        setTransactions(response.data);
      } catch (error) {
        console.error("Error fetching invoice transactions:", error);
      }
    };

    fetchInvoice();
    fetchTransactions();
  }, [invoiceId]);

  const handleShowInvoiceMetadata = () => {
    setShowInvoiceMetadata(true);
  };

  const handleCloseInvoiceMetadata = () => {
    setShowInvoiceMetadata(false);
  };

  const handleShowTransactionMetadata = (metadata) => {
    setTransactionMetadata(metadata);
    setShowTransactionMetadata(true);
  };

  const handleCloseTransactionMetadata = () => {
    setShowTransactionMetadata(false);
  };

  if (!invoice) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flexDiv">
      <h1>Invoice Details</h1>
      <p>
        <strong>Invoice Number:</strong> {invoice.invoiceNumber}
      </p>
      <p>
        <strong>Invoice Date:</strong> {invoice.invoiceDate}
      </p>
      <p>
        <strong>Due Date:</strong> {invoice.dueDate}
      </p>
      <p>
        <strong>Amount:</strong> {invoice.amount}
      </p>
      <p>
        <strong>Customer:</strong>
        <Link to={`/customers/${invoice.customerId}`}>
          {invoice.customer.name}
        </Link>
      </p>
      <p>
        <strong>Client:</strong>
        <Link to={`/clients/${invoice.clientId}`}>{invoice.client.name}</Link>
      </p>
      <p>
        <strong>Schedule:</strong>
        {invoice.scheduleId ? (
          <Link to={`/schedules/${invoice.scheduleId}`}>
            {invoice.schedule?.scheduleNumber ||
              `Schedule #${invoice.scheduleId}`}
          </Link>
        ) : (
          "No Schedule"
        )}
      </p>
      <p>
        <strong>Payment Terms:</strong> {invoice.paymentTerms}
      </p>
      <p>
        <strong>Status:</strong> {invoice.status}
      </p>
      <p>
        <strong>Description:</strong> {invoice.description}
      </p>
      <p>
        <strong>Balance:</strong> {invoice.balance}
      </p>
      <button onClick={handleShowInvoiceMetadata}>Show Metadata</button>
      <GenericMetadata
        metadata={invoice.metadata}
        showMetadata={showInvoiceMetadata}
        handleClose={handleCloseInvoiceMetadata}
      />
      <h2>Invoice Transactions</h2>
      <table className="transactions-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Type</th>
            <th>Source</th>
            <th>Comment</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <tr key={transaction.id}>
              <td>{transaction.date}</td>
              <td>{transaction.amount}</td>
              <td>{transaction.type}</td>
              <td>{transaction.source}</td>
              <td>{transaction.comment}</td>
              <td>
                <button
                  onClick={() =>
                    handleShowTransactionMetadata(transaction.metadata)
                  }
                >
                  Show Metadata
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <GenericMetadata
        metadata={transactionMetadata}
        showMetadata={showTransactionMetadata}
        handleClose={handleCloseTransactionMetadata}
      />
    </div>
  );
}

export default InvoiceDetails;
