import React, { useState, useEffect, memo } from 'react';
import CustomerStatement from './CustomerStatement';
import { fetchStatements, generateStatement } from '../utils/apiCustomer';

function CustomerDocumentsTab({ customerId }) {
  const [statements, setStatements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedStatement, setSelectedStatement] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  useEffect(() => {
    if (!customerId) return;

    const loadStatements = async () => {

      setIsLoading(true);
      try {
        const statementsData = await fetchStatements(customerId);
        setStatements(statementsData);
      } catch (error) {
        console.error('Error fetching statements:', error);
      }
      setIsLoading(false);
    };

    loadStatements();
  }, [customerId, refreshTrigger]);

  const handleGenerateStatement = async () => {
    setIsGenerating(true);
    try {
      await generateStatement(customerId);
      setRefreshTrigger(prev => prev + 1); // ✅ Triggers a re-fetch
    } catch (error) {
      console.error('Error generating statement:', error);
      alert('Failed to generate statement.');
    }
    setIsGenerating(false);
  };

  const handleToggleStatement = (statement) => {
    setSelectedStatement(prev => (prev?.id === statement.id ? null : statement));
  };

  return (
    <div>
      <h2>Customer Statements</h2>
      <button onClick={handleGenerateStatement} disabled={isGenerating}>
        {isGenerating ? "Generating..." : "Generate New Statement"}
      </button>
      {isLoading ? (
        <p>Loading statements...</p>
      ) : statements.length === 0 ? (
        <p>No statements available.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Statement Date</th>
              <th>Statement Number</th>
              <th>Balance</th>
              <th>Past Due Balance</th>
              <th>Status</th>
              <th>View Details</th>
            </tr>
          </thead>
          <tbody>
            {statements.map((statement) => (
              <React.Fragment key={statement.id}>
                <tr>
                  <td>{statement.date}</td>
                  <td>{statement.number || 'N/A'}</td>
                  <td>${Number(statement.balance).toFixed(2)}</td>
                  <td>${Number(statement.balancePastDue).toFixed(2)}</td>
                  <td>{statement.accountStatus}</td>
                  <td>
                    <button onClick={() => handleToggleStatement(statement)}>
                      {selectedStatement?.id === statement.id ? "Hide" : "View"}
                    </button>
                  </td>
                </tr>
                {selectedStatement?.id === statement.id && (
                  <tr>
                    <td colSpan="6">
                      <CustomerStatement statement={selectedStatement} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

// ✅ Prevents unnecessary re-renders
export default memo(CustomerDocumentsTab);
