import React from "react";
import InvoiceTransactions from "./InvoiceTransactions";

const ClientCreditMemoInvoiceTransactions = () => {
  return (
    <InvoiceTransactions
      submitUrl="/credit-memos/"
      title="Client Credit Memo"
      numberLabel="Credit Memo Number"
      amountLabel="Credit Memo Amount"
      submitButtonLabel="Submit Credit Memo"
    />
  );
};

export default ClientCreditMemoInvoiceTransactions;
