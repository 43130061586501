import React, { useState, useEffect } from "react";
import axiosInstance from "../../axiosConfig";
import config from "../../config";
import "./SelectAccountsPayable.css";

const SelectAccountsPayable = ({
  entityType,
  entityId,
  selectedAccountsPayable = [],
  onSelectAccountsPayable,
}) => {
  const [nonZeroBalanceAccounts, setNonZeroBalanceAccounts] = useState([]);
  const [zeroBalanceAccounts, setZeroBalanceAccounts] = useState([]);
  const [showPaymentHistory, setShowPaymentHistory] = useState(false);

  useEffect(() => {
    const fetchAccountsPayable = async () => {
      try {
        const response = await axiosInstance.get(
          `${config.API_URL}/accounts-payable`,
          {
            params: {
              entityType,
              entityId,
            },
          }
        );

        const fetchedData = response.data;

        const nonZeroBalance = fetchedData
          .filter((account) => parseFloat(account.balance) > 0)
          .sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate));

        const zeroBalance = fetchedData
          .filter((account) => parseFloat(account.balance) === 0)
          .sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate));

        setNonZeroBalanceAccounts(nonZeroBalance);
        setZeroBalanceAccounts(zeroBalance);
      } catch (error) {
        console.error("Error fetching accounts payable:", error);
      }
    };

    if (entityId) {
      fetchAccountsPayable();
    }
  }, [entityType, entityId]);

  const handleSelect = (account) => {
    const isSelected = selectedAccountsPayable.find(
      (item) => item.id === account.id
    );
    const newSelectedAccountsPayable = isSelected
      ? selectedAccountsPayable.filter((item) => item.id !== account.id)
      : [...selectedAccountsPayable, account];

    onSelectAccountsPayable(newSelectedAccountsPayable);
  };

  const renderTable = (accounts, selectable = true) => (
    <div className="accounts-payable-table-container">
      <table className="accounts-payable-table">
        <thead>
          <tr>
            {selectable && <th>Select</th>}
            <th>ID</th>
            <th>Type</th>
            <th>Description</th>
            <th>Amount</th>
            <th>Balance</th>
            <th>Due Date</th>
          </tr>
        </thead>
        <tbody>
          {accounts.map((account) => {
            const isChecked = !!selectedAccountsPayable.find(
              (item) => item.id === account.id
            );
            return (
              <tr
                key={account.id}
                onClick={() => handleSelect(account)}
                className={selectable && isChecked ? "selected" : ""}
              >
                {selectable && (
                  <td onClick={(e) => e.stopPropagation()}>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleSelect(account);
                      }}
                    />
                  </td>
                )}
                <td>
                  {account.payableSourceType === "Schedule" && account.schedule
                    ? account.schedule.scheduleNumber
                    : account.payableSourceType === "CollectionReport" && account.collectionReport
                    ? account.collectionReport.collectionReportNumber
                    : account.payableSourceId}
                </td>
                <td>{account.payableSourceType}</td>
                <td>{account.description}</td>
                <td>{account.amount}</td>
                <td>{account.balance}</td>
                <td>{new Date(account.dueDate).toLocaleDateString()}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="accounts-payable-container">
      <h3>Accounts with Non-Zero Balance</h3>
      {nonZeroBalanceAccounts.length > 0 ? (
        renderTable(nonZeroBalanceAccounts)
      ) : (
        <p>No accounts with non-zero balance.</p>
      )}

      <h3
        className="toggleable-header"
        onClick={() => setShowPaymentHistory(!showPaymentHistory)}
      >
        {showPaymentHistory ? "Hide Payment History" : "Show Payment History"}
      </h3>

      <div
        className={`payment-history-container-2 ${
          showPaymentHistory ? "visible" : ""
        }`}
      >
        {showPaymentHistory && zeroBalanceAccounts.length > 0 ? (
          renderTable(zeroBalanceAccounts, false)
        ) : (
          <p>No payment history records found.</p>
        )}
      </div>
    </div>
  );
};

export default SelectAccountsPayable;
