import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import './Navigation.css';

const subMenuStateKey = 'subMenuState';

// Mapping static paths to page titles
const pageTitles = {
  "/": "Home",
  "/factoring-dashboard": "Factoring Dashboard",
  "/pending-actions-dashboard": "Pending Actions Dashboard",
  "/role-management": "Role Management",
  "/job-management": "Job Management",
  "/log-management": "Log Management",
  "/add-employee": "Add Employee",
  "/employee-search": "Employee Search",
  "/payroll-input": "Payroll Input",
  "/add-client": "Add Client",
  "/client-search": "Client Search",
  "/purchase-order-search": "Purchase Order Search",
  "/add-schedule": "Add Schedule",
  "/schedule-search": "Schedule Search",
  "/generate-collection-report": "Generate Collection Report",
  "/collection-reports-dashboard": "Collection Reports Dashboard",
  "/create-payment": "Create Payment",
  "/add-customer": "Add Customer",
  "/customer-search": "Customer Search",
  "/call-customer": "Call Customer",
  "/add-supplier": "Add Supplier",
  "/supplier-search": "Supplier Search",
  "/add-supplier-invoice": "Add Supplier Invoice",
  "/client-credit-memo": "Client Credit Memo",
  "/customer-deposit": "Customer Deposit",
  "/irs-reports": "IRS Reports",
  "/accounts-payable-search": "Accounts Payable Search",
  "/balance-sheet-manager": "Balance Sheets",
  "/income-statement-manager": "Income Statements",
  "/customers": "Customer Details",
  "/login": "Login",
  "/register": "Register",
  "/statements": "Statements",
  "/orders/new": "Add Purchase Order",
};

// Function to determine dynamic page titles
const getPageTitle = (pathname) => {
  if (pageTitles[pathname]) return pageTitles[pathname];

  // Handle dynamic routes
  if (pathname.startsWith("/customers/")) {
    return "Customer Details";
  }
  if (pathname.startsWith("/clients/")) {
    return "Client Details";
  }
  if (pathname.startsWith("/invoices/")) {
    return "Invoice Details";
  }
  if (pathname.startsWith("/features/")) {
    return "Features";
  }
  if (pathname.startsWith("/schedules/")) {
    return "Schedule Details";
  }
  if (pathname.startsWith("/purchase-orders/")) {
    return "Purchase Order Details";
  }
  if (pathname.startsWith("/suppliers/")) {
    return "Supplier Details";
  }

  return pathname;
};

function Navigation({ isSidebarOpen, isSidebarPinned, closeSidebar }) {
  const { user } = useUserContext();
  const location = useLocation();  

  // Update title when route changes
  useEffect(() => {
    const newTitle = `AFI - ${getPageTitle(location.pathname)}`;
    document.title = `${newTitle}`;
  }, [location.pathname]);

  const getInitialSubMenuState = () => {
    const savedState = localStorage.getItem(subMenuStateKey);
    return savedState
      ? JSON.parse(savedState)
      : {
          clients: true,
          customers: true,
          invoices: true,
          payments: true,
          fundings: true,
          reports: true,
          accounting: true,
          schedules: true,
          advances: true,
          deposits: true,
          collections: true,
          orders: true,
        };
  };

  const [openSections, setOpenSections] = useState(getInitialSubMenuState);

  useEffect(() => {
    localStorage.setItem(subMenuStateKey, JSON.stringify(openSections));
  }, [openSections]);

  const toggleSection = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleLinkClick = () => {
    closeSidebar();
  };

  const renderLink = (path, label, key, exact = false) => (
    <li key={key}>
      <NavLink
        to={path}
        className={({ isActive }) => (isActive ? 'active' : '')}
        end={exact}
        onClick={() => handleLinkClick()}
      >
        {label}
      </NavLink>
    </li>
  );

  const renderSection = (section, label, links) => (
    <li key={section}>
      <div onClick={() => toggleSection(section)} className={openSections[section] ? 'active' : ''}>
        {label}
      </div>
      <ul className={openSections[section] ? 'expanded' : ''}>
        {links.map((link, index) => React.cloneElement(link, { key: index }))}
      </ul>
    </li>
  );

  return (
    <div className={`sidebar-container ${isSidebarPinned ? `pinned` : isSidebarOpen ? 'open' : 'collapsed'}`}>
      <nav className="sidebar">
        <ul>
          {renderLink("/", "Home", "home-link")}
          {user && (user.role === 'admin' || user.role === 'employee') && renderLink("/factoring-dashboard", "Factoring Dashboard", "factoring-dashboard-link")}
          {user && (user.role === 'admin' || user.role === 'employee') && renderLink("/pending-actions-dashboard", "Pending Actions Dashboard", "pending-actions-dashboard-link")}

          {user && (user.role === 'admin') && renderSection('admin', 'Admin', [
            renderLink("/role-management", "Role Management", "role-management"),
            renderLink("/job-management", "Job Management", "job-management-link"),
            renderLink("/log-management", "Log Management", "log-management-link"),
            renderLink("/add-employee", "Add Employee", "add-employee-link"),
            renderLink("/employee-search", "Employee Search", "employee-search-link"),
            renderLink("/payroll-input", "Payroll Input", "payroll-input-link"),
          ])}

          {user && (user.role === 'admin' || user.role === 'employee') && renderSection('clients', 'Clients', [
            renderLink("/add-client", "Add Client", "add-client-link"),
            renderLink("/client-search", "Client Search", "client-search-link"),
            <hr key="clients-hr-1" />,
            renderLink("/orders/new", "Add Purchase Order", "receive-order-link"),
            renderLink("/purchase-order-search", "Purchase Order Search", "purchase-order-search-link"),
            <hr key="clients-hr-2" />,
            renderLink("/add-schedule", "Add Schedule", "add-schedule-link"),
            renderLink("/schedule-search", "Schedule Search", "schedule-search-link"),
            // <hr key="clients-hr-3" />,
            // renderLink("/generate-collection-report", "Collection Report", "collection-report-link"),
            // renderLink("/collection-reports-dashboard", "Collection Reports Dashboard", "collection-reports-dashboard-link"),
          ])}

          {user && (user.role === 'admin' || user.role === 'employee') && renderSection('customers', 'Customers', [
            renderLink("/add-customer", "Add Customer", "add-customer-link"),
            renderLink("/customer-search", "Customer Search", "customer-search-link"),
            <hr key="customers-hr-1" />,
            renderLink("/call-customer", "Call Customers", "call-customer-link"),
            renderLink("/statements", "Create Statements", "statements-link"),
          ])}

          {user && (user.role === 'admin' || user.role === 'employee') && renderSection('suppliers', 'Suppliers', [
            renderLink("/add-supplier", "Add Supplier", "add-supplier-link"),
            renderLink("/supplier-search", "Supplier Search", "supplier-search-link"),
          ])}

          {user && (user.role === 'admin' || user.role === 'employee') && renderSection('transactions', 'Transactions', [
            renderLink("/client-credit-memo", "Apply Credit Memos", "client-credit-memo-link"),
            renderLink("/customer-deposit", "Enter Deposits", "customer-deposit-link"),
            renderLink("/create-payment", "Make Payments", "create-payment-link"),
            renderLink("/add-supplier-invoice", "Add A/P Invoices", "add-supplier-invoice-link"),
          ])}

          {user && (user.role === 'admin' || user.role === 'employee') && renderSection('accounting', 'Accounting', [
            renderLink("/irs-reports", "IRS Reports", "irs-reports-link"),
            renderLink("/accounts-payable-search", "Accounts Payable Search", "accounts-payable-search-link"),
            renderLink("/balance-sheet-manager", "Balance Sheets", "balance-sheets-link"),
            renderLink("/income-statement-manager", "Income Statements", "income-statements-link"),
          ])}

          {user && (user.role === 'client') && renderSection('client-portal', 'Client Portal', [
            renderLink("/client-dashboard", "Dashboard", "client-dashboard-link"),
            renderLink("/client-invoices", "Invoices", "client-invoices-link"),
            renderLink("/client-funding", "Funding", "client-funding-link"),
            renderLink("/client-payments", "Payments", "client-payments-link"),
            renderLink("/client-reports", "Reports", "client-reports-link"),
            renderLink("/client-documents", "Documents", "client-documents-link"),
          ])}

          {user && (user.role === 'customer') && renderSection('customer-portal', 'Customer Portal', [
            renderLink("/customer-dashboard", "Dashboard", "customer-dashboard-link"),
            renderLink("/payment-portal", "Payment Portal", "payment-portal-link"),
            renderLink("/payment-history", "Payment History", "payment-history-link"),
            renderLink("/credit-request", "Credit/RA Request", "credit-ra-request-link"),
          ])}
        </ul>
      </nav>
    </div>
  );
}

export default Navigation;
