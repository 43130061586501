import React, { useState, useEffect, useMemo, useCallback } from "react";
import axiosInstance from "../axiosConfig";
import PendingActionModal from "./PendingActionModal";
import Modal from "react-modal";
import dayjs from "dayjs";

import config from "../config";
import {
  Table,
  Input,
  Button,
  Form,
  notification,
  Checkbox,
  Col,
  Row,
  Tag,
  Tooltip,
  Select,
  Spin,
} from "antd";
import { PlusOutlined, EyeOutlined } from "@ant-design/icons";
import GenericSearch from "./GenericSearch";
import entitySearchConfig from "../entitySearchConfig";

import "./InvoiceTransactions.css";

// Ensure that the modal knows which element it should be attached to
Modal.setAppElement("#root");

const InvoiceTransactions = ({
  submitUrl,              // API endpoint to submit the form
  title,                  // Page header
  numberLabel,            // "Check Number" or "Credit Memo Number"
  amountLabel,            // "Check Amount" or "Credit Memo Amount"
  submitButtonLabel,      // Button text
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [pinnedCustomers, setPinnedCustomers] = useState([]);
  const [openInvoices, setOpenInvoices] = useState([]);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [mostRecentlyPinnedCustomer, setMostRecentlyPinnedCustomer] =
    useState(null);

  // Customer Deposit state
  const [checkNumber, setCheckNumber] = useState("");
  const [checkAmount, setCheckAmount] = useState(0);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);

  // View/Create Pending Action modal state
  const [showCreatePendingActionModal, setShowCreatePendingActionModal] =
    useState(false);
  const [showPendingActionsModal, setShowPendingActionsModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  // Create Invoice modal state
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [isCreateInvoiceModalOpen, setIsCreateInvoiceModalOpen] =
    useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [invoiceAllocation, setInvoiceAllocation] = useState(remainingAmount);
  const [isFictitious, setIsFictitious] = useState(true); // ✅ Default to checked
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Customer Pending Action modal state
  const [selectedCustomerPendingActions, setSelectedCustomerPendingActions] =
    useState([]);
  const [
    selectedCustomerForPendingActions,
    setSelectedCustomerForPendingActions,
  ] = useState(null);
  const [showCustomerPendingActionsModal, setShowCustomerPendingActionsModal] =
    useState(false);

  // Customer Create Pending Action modal state
  const [
    showCreateCustomerPendingActionModal,
    setShowCreateCustomerPendingActionModal,
  ] = useState(false);
  const [
    selectedCustomerForNewPendingAction,
    setSelectedCustomerForNewPendingAction,
  ] = useState(null);

  // Form state
  const [form] = Form.useForm();
  const [validationErrors, setValidationErrors] = useState({
    checkNumber: "",
    checkAmount: "",
    selectedSuspenseCustomerId: "",
  });

  const validateForm = () => {
    let errors = {};

    if (!checkNumber) {
      errors.checkNumber = `${numberLabel} is required`;
    }
    
    if (checkAmount === "" || isNaN(checkAmount)) {
      errors.checkAmount = `${amountLabel} is required`;
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const resetPageState = () => {
    setPinnedCustomers([]);
    setOpenInvoices([]);
    setCheckAmount("");
    setCheckNumber("");
    setSelectedClient(null);
    setSelectedCustomer(null);
    setMostRecentlyPinnedCustomer(null);
    form.resetFields();
  };

  const fetchCustomerPendingActions = async (customerId) => {
    try {
      const response = await axiosInstance.get(
        `${config.API_URL}/customers/${customerId}/pending-actions`
      );
      setSelectedCustomerPendingActions(response.data);
      setShowCustomerPendingActionsModal(true);
    } catch (error) {
      console.error("Error fetching pending actions for customer:", error);
      notification.error({
        message: "Error",
        description:
          "Could not fetch pending actions for the selected customer.",
      });
    }
  };

  const fetchOpenInvoices = useCallback(async () => {
    try {
      const customerIds = pinnedCustomers.map((c) => c.id).join(",");
      if (!customerIds) {
        setOpenInvoices([]);
        return;
      }
      const response = await axiosInstance.get(
        `${config.API_URL}/invoices/customers/open-invoices?customerIds=${customerIds}`
      );

      setOpenInvoices((prevInvoices) => {
        const prevInvoiceMap = prevInvoices?.reduce((map, inv) => {
          map[inv.id] = inv;
          return map;
        }, {});

        return response.data.map((invoice) => {
          const prevInvoice = prevInvoiceMap[invoice.id];
          const allocation = prevInvoice ? prevInvoice.allocation : 0;
          const closeInvoice = prevInvoice ? prevInvoice.closeInvoice : false;

          return {
            ...invoice,
            customerName: invoice.customer.name,
            customerAccount: invoice.customer.account,
            pendingActions: invoice.pendingActions || [],
            allocation: allocation || 0,
            closeInvoice: closeInvoice,
          };
        });
      });
    } catch (error) {
      console.error("Error fetching open invoices:", error);
    }
  }, [pinnedCustomers]);

  useEffect(() => {
    if (isCreateInvoiceModalOpen) {
      setInvoiceAllocation(remainingAmount); // ✅ Set allocation when modal opens
      setIsFictitious(true); // ✅ Reset to checked when modal opens
    }
  }, [isCreateInvoiceModalOpen, remainingAmount]);

  useEffect(() => {
    if (pinnedCustomers.length) {
      fetchOpenInvoices(pinnedCustomers.map((c) => c.id));
    } else {
      setOpenInvoices([]);
    }
  }, [pinnedCustomers, fetchOpenInvoices]);

  const openNewCustomerPendingAction = (customer) => {
    setSelectedCustomerForNewPendingAction(customer);
    setShowCreateCustomerPendingActionModal(true);
  };

  const openCustomerModal = () => {
    setIsCustomerModalOpen(true);
  };

  const openClientModal = () => {
    setIsClientModalOpen(true);
  };

  const handleCreateInvoice = async () => {
    if (!selectedClient?.id || !selectedCustomer?.id) {
      notification.error({
        message: "Please select both a client and a customer.",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axiosInstance.post(
        `${config.API_URL}/invoices/create-fictitious`,
        {
          clientId: selectedClient.id,
          customerId: selectedCustomer.id,
          isFictitious,
        }
      );

      if (response.data.success) {
        notification.success({
          message: `Invoice #${response.data.invoiceNumber} created successfully!`,
        });

        // ✅ Refresh open invoices
        await fetchOpenInvoices();

        // ✅ Find the new invoice by invoiceNumber and update its allocation
        setOpenInvoices((prev) =>
          prev.map((inv) =>
            inv.invoiceNumber === response.data.invoiceNumber
              ? { ...inv, allocation: parseFloat(invoiceAllocation) || 0 }
              : inv
          )
        );

        // ✅ Recalculate remaining amount
        setRemainingAmount((prevRemaining) =>
          parseFloat((prevRemaining - invoiceAllocation).toFixed(2))
        );

        setIsSubmitting(false);
        setIsCreateInvoiceModalOpen(false);
      } else {
        notification.error({ message: "Failed to create invoice." });
        setIsSubmitting(false);
      }
    } catch (error) {
      notification.error({ message: "Error creating invoice." });
      setIsSubmitting(false);
    }
  };

  const handleClientSelect = (client) => {
    console.log(client);
    if (!client.id || !client.name) return;
    setSelectedClient(client);
  };

  const handleCustomerSelect = (customer) => {
    if (!customer.id || !customer.name) return;

    if (pinnedCustomers.some((c) => c.id === customer.id)) {
      notification.warning({
        message: "Customer Already Selected",
        description: `Custer ${customer.name} has already been selected.`,
      });
      return;
    }
    setPinnedCustomers((prev) => [...prev, customer]);
    setMostRecentlyPinnedCustomer(customer);
  };

  const handleRemoveCustomer = (customerId) => {
    setPinnedCustomers((prev) => prev.filter((c) => c.id !== customerId));
  };

  const handleApplyFullBalance = useCallback(
    (invoiceId, balance) => {
      setOpenInvoices((prev) => {
        const updatedInvoices = prev.map((inv) =>
          inv.id === invoiceId
            ? { ...inv, allocation: parseFloat(balance), closeInvoice: true }
            : inv
        );

        // 🔹 Recalculate remaining amount based on total allocated
        const totalAllocated = updatedInvoices.reduce(
          (sum, inv) => sum + (parseFloat(inv.allocation) || 0),
          0
        );

        setRemainingAmount(
          parseFloat((checkAmount - totalAllocated).toFixed(2))
        ); // ✅ Fix floating point issues
        return updatedInvoices;
      });
    },
    [checkAmount]
  );

  const handleCheckAmountChange = (e) => {
    const newCheckAmount = parseFloat(e.target.value) || 0;
    setCheckAmount(newCheckAmount);

    // 🔹 Ensure remainingAmount is recalculated properly after invoices update
    setRemainingAmount(() => {
      const totalAllocated = openInvoices.reduce(
        (sum, inv) => sum + (parseFloat(inv.allocation) || 0),
        0
      );
      return parseFloat((newCheckAmount - totalAllocated).toFixed(2));
    });
  };

  const handleCreatePendingAction = (invoice) => {
    setSelectedInvoice(invoice);
    setShowCreatePendingActionModal(true);
  };

  const handleViewPendingActions = (invoice) => {
    setSelectedInvoice(invoice);
    setShowPendingActionsModal(true);
  };

  const handleToggleCloseInvoice = (invoiceId, checked) => {
    setOpenInvoices((prev) =>
      prev.map((inv) =>
        inv.id === invoiceId ? { ...inv, closeInvoice: checked } : inv
      )
    );
  };

  const handleAllocationChange = useCallback(
    (invoiceId, amount) => {
      setOpenInvoices((prev) => {
        const updatedInvoices = prev.map((inv) =>
          inv.id === invoiceId
            ? { ...inv, allocation: parseFloat(amount) || 0 }
            : inv
        );

        // 🔹 Calculate total allocated **AFTER** invoices update
        const totalAllocated = updatedInvoices.reduce(
          (sum, inv) => sum + (parseFloat(inv.allocation) || 0),
          0
        );

        setRemainingAmount(parseFloat(checkAmount) - totalAllocated);

        return updatedInvoices;
      });
    },
    [checkAmount]
  );

  const handleSubmit = async () => {
    if (!validateForm()) return; // Prevent submission if validation fails

    try {
      if (!checkNumber) {
        notification.error({
          message: "Missing Check Number",
          description:
            "Please enter a check number before submitting the deposit.",
        });
        return;
      }

      if (remainingAmount < 0) {
        notification.error({
          message: "Over Allocation Detected",
          description:
            "The total allocated amount cannot exceed the check amount.",
        });

        return;
      }

      if (remainingAmount > 0) {
        notification.error({
          message: "Unallocated Funds Remaining",
          description:
            "The remaining amount must be fully allocated before submitting.",
        });

        return;
      }

      setIsLoading(true); // ✅ Lock the UI

      const payments = openInvoices
        .filter((inv) => inv.allocation > 0 || inv.closeInvoice)
        .map((inv) => ({
          invoiceId: inv.id,
          amount: inv.allocation,
          clientId: inv.clientId,
          customerId: inv.customerId,
          closeInvoice: inv.closeInvoice,
        }));

      const payload = {
        date: new Date(),
        checkNumber,
        checkAmount: parseFloat(checkAmount),
        payments,
      };

      try {
        await axiosInstance.post(`${config.API_URL}${submitUrl}`, payload);
        notification.success({ message: "Deposit recorded successfully" });
        resetPageState();
      } catch (error) {
        notification.error({ message: "Error recording deposit" });
      }
    } catch (error) {
      notification.error({ message: "Error recording deposit." });
    }

    setIsLoading(false);
  };

  const totalAllocation = useMemo(() => {
    return openInvoices?.reduce(
      (acc, invoice) => acc + (parseFloat(invoice.allocation) || 0),
      0
    );
  }, [openInvoices]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  const getUniqueValues = (array, key) => {
    const uniqueValues = new Set(array?.map((item) => key(item)));
    return Array.from(uniqueValues).map((value) => ({ text: value, value }));
  };

  const columns = useMemo(() => {
    return [
      {
        title: "Customer Name",
        dataIndex: ["customer", "name"],
        key: "customerName",
        sorter: (a, b) => a.customer.name.localeCompare(b.customer.name),
        sortDirections: ["ascend", "descend"],
        filters: getUniqueValues(
          openInvoices,
          (invoice) => invoice.customer.name
        ),
        onFilter: (value, record) => record.customer.name.includes(value),
        render: (name) => (
          <Tooltip placement="topLeft" title={name}>
            <span className="ellipsis">{name}</span>
          </Tooltip>
        ),
      },
      {
        title: "Client Name",
        dataIndex: ["client", "name"],
        key: "clientName",
        sorter: (a, b) => a.client.name.localeCompare(b.client.name),
        sortDirections: ["ascend", "descend"],
        filters: getUniqueValues(
          openInvoices,
          (invoice) => invoice.client.name
        ),
        onFilter: (value, record) => record.client.name.includes(value),
        render: (name) => (
          <Tooltip placement="topLeft" title={name}>
            <span className="ellipsis">{name}</span>
          </Tooltip>
        ),
      },
      {
        title: "Invoice Number",
        dataIndex: "invoiceNumber",
        key: "invoiceNumber",
        sorter: (a, b) => a.invoiceNumber - b.invoiceNumber,
        sortDirections: ["ascend", "descend"],
        filters: getUniqueValues(
          openInvoices,
          (invoice) => invoice.invoiceNumber
        ),
        onFilter: (value, record) => record.invoiceNumber.includes(value),
        render: (invoiceNumber, record) => (
          <a
            href={`/invoices/${record.id}`} // ✅ Correct link path
            target="_blank" // ✅ Open in new tab
            rel="noopener noreferrer" // ✅ Security best practice
            style={{ textDecoration: "underline", color: "#1890ff" }}
          >
            {invoiceNumber}
          </a>
        ),
      },
      {
        title: "Invoice Date",
        dataIndex: "invoiceDate",
        key: "invoiceDate",
        sorter: (a, b) => new Date(a.invoiceDate) - new Date(b.invoiceDate),
        sortDirections: ["ascend", "descend"],
        render: (date) => (date ? dayjs(date).format("YYYY-MM-DD") : "-"),
      },
      {
        title: "Close Date",
        dataIndex: "closeDate",
        key: "closeDate",
        sorter: (a, b) =>
          new Date(a.closeDate || 0) - new Date(b.closeDate || 0),
        render: (date) => (date ? dayjs(date).format("YYYY-MM-DD") : "-"),
      },
      {
        title: "Collect Date",
        dataIndex: "collectDate",
        key: "collectDate",
        sorter: (a, b) =>
          new Date(a.collectDate || 0) - new Date(b.collectDate || 0),
        render: (date) => (date ? dayjs(date).format("YYYY-MM-DD") : "-"),
      },
      {
        title: "Fictitious?",
        dataIndex: "isFictitious",
        key: "isFictitious",
        sorter: (a, b) => a.isFictitious - b.isFictitious,
        filters: [
          { text: "Fictitious", value: true },
          { text: "Real", value: false },
        ],
        onFilter: (value, record) => record.isFictitious === value,
        render: (isFictitious) => (isFictitious ? "Yes" : "No"),
      },
      {
        title: "Invoice Amount",
        dataIndex: "amount",
        key: "amount",
        sorter: (a, b) => a.amount - b.amount,
        sortDirections: ["ascend", "descend"],
        render: (amount) => formatCurrency(amount),
      },
      {
        title: "Invoice Balance",
        dataIndex: "balance",
        key: "balance",
        sorter: (a, b) => a.balance - b.balance,
        sortDirections: ["ascend", "descend"],
        render: (balance, record) => (
          <Tooltip title="Click to apply full amount">
            <span
              style={{
                cursor: "pointer",
                color: "#1890ff",
                textDecoration: "underline",
              }}
              onClick={() => handleApplyFullBalance(record.id, balance)}
            >
              {formatCurrency(balance)}
            </span>
          </Tooltip>
        ),
      },
      {
        title: "Allocate",
        key: "allocate",
        sorter: (a, b) =>
          (parseFloat(a.allocation) || 0) - (parseFloat(b.allocation) || 0),
        filters: [
          { text: "Is Not Empty", value: "notEmpty" }, // 🔹 New filter option
        ],
        onFilter: (value, record) => {
          if (value === "notEmpty") {
            const allocationValue = parseFloat(record.allocation);
            return !isNaN(allocationValue) && allocationValue !== 0; // 🔹 Exclude 0 and empty values
          }
          return true;
        },
        render: (_, record) => (
          <Input
            type="number"
            value={record.allocation || ""}
            onChange={(e) => handleAllocationChange(record.id, e.target.value)}
            onFocus={(e) => e.target.select()}
          />
        ),
      },
      {
        title: "Close Invoice",
        dataIndex: "closeInvoice",
        key: "closeInvoice",
        align: "center",
        render: (_, record) => (
          <Checkbox
            checked={record.closeInvoice}
            onChange={(e) =>
              handleToggleCloseInvoice(record.id, e.target.checked)
            }
          />
        ),
      },
      {
        title: "Pending Actions",
        key: "actions",
        render: (_, record) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              icon={<PlusOutlined />}
              onClick={() => handleCreatePendingAction(record)}
            ></Button>
            {record.pendingActions.length > 0 && (
              <Button
                icon={<EyeOutlined />}
                onClick={() => handleViewPendingActions(record)}
                style={{ marginTop: 4 }}
              ></Button>
            )}
          </div>
        ),
      },
    ];
  }, [openInvoices, handleAllocationChange, handleApplyFullBalance]);

  return (
    <div className="flexDiv">
      {isLoading && (
        <div className="overlay">
          <div className="spin-container">
            <Spin size="large" />
            <span className="loading-text">Submitting Deposit...</span>
          </div>
        </div>
      )}

      <Row gutter={16}>
        <Col span={24} style={{ height: "calc(100vh - 60px)" }}>
          <Form
            form={form}
            style={{ height: "calc(100vh - 60px)" }}
            onFinish={handleSubmit}
            initialValues={{
              checkAmount,
              checkNumber,
            }}
          >
            <h1>{title}</h1>
            <div className="form-header">
              <div className="flex-item full-width">
                <Form.Item name="customer">
                  <label>
                    Customer Search:
                    <div className="inline-search-container">
                      <GenericSearch
                        config={entitySearchConfig.customer}
                        onSelectItem={handleCustomerSelect}
                        inlineSearch={true}
                        autoFocus={true}
                        openModal={openCustomerModal}
                        selectedValue={mostRecentlyPinnedCustomer?.name}
                      />
                    </div>
                  </label>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  validateStatus={validationErrors.checkNumber ? "error" : ""}
                >
                  <Tooltip
                    title={validationErrors.checkNumber}
                    open={!!validationErrors.checkNumber} // Dynamically show/hide tooltip
                    placement="top" // ⬆️ Show tooltip above the field
                  >
                    <label>
                      {numberLabel}:
                      <Input
                        value={checkNumber}
                        onChange={(e) => {
                          setCheckNumber(e.target.value);
                          if (e.target.value.trim()) {
                            setValidationErrors((prev) => ({
                              ...prev,
                              checkNumber: "",
                            }));
                          }
                        }}
                        placeholder={`Enter ${numberLabel.toLowerCase()}`}
                      />
                    </label>
                  </Tooltip>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  validateStatus={validationErrors.checkAmount ? "error" : ""}
                >
                  <Tooltip
                    title={validationErrors.checkAmount}
                    open={!!validationErrors.checkAmount}
                    placement="top" // ⬆️ Show tooltip above the field
                  >
                    <label>
                      {amountLabel}:
                      <Input
                        type="number"
                        value={checkAmount}
                        onChange={handleCheckAmountChange}
                        placeholder={`Enter ${amountLabel.toLowerCase()}`}
                      />
                    </label>
                  </Tooltip>
                </Form.Item>
              </div>
            </div>

            <Modal
              isOpen={isCustomerModalOpen}
              onRequestClose={() => setIsCustomerModalOpen(false)}
              contentLabel="Customer Search"
              className="search-modal"
              overlayClassName="search-modal-overlay"
            >
              <div className="search-content">
                <div className="search-header">
                  <h2>Customer Search</h2>
                  <button onClick={() => setIsCustomerModalOpen(false)}>
                    &times;
                  </button>
                </div>
                <div className="search-body">
                  <GenericSearch
                    config={entitySearchConfig.customer}
                    onSelectItem={handleCustomerSelect}
                    closeModal={() => setIsCustomerModalOpen(false)}
                    inModal={true}
                  />
                </div>
              </div>
            </Modal>

            <Modal
              isOpen={isClientModalOpen}
              onRequestClose={() => setIsClientModalOpen(false)}
              contentLabel="Client Search"
              className="search-modal"
              overlayClassName="search-modal-overlay"
            >
              <div className="search-content">
                <div className="search-header">
                  <h2>Client Search</h2>
                  <button onClick={() => setIsClientModalOpen(false)}>
                    &times;
                  </button>
                </div>
                <div className="search-body">
                  <GenericSearch
                    config={entitySearchConfig.client}
                    onSelectItem={handleClientSelect}
                    closeModal={() => setIsClientModalOpen(false)}
                    inModal={true}
                  />
                </div>
              </div>
            </Modal>

            <div className="pinned-customers">
              {pinnedCustomers.map((customer) => (
                <Tag
                  key={customer.id}
                  closable
                  onClose={() => handleRemoveCustomer(customer.id)}
                >
                  <Tooltip title="View Pending Actions">
                    <EyeOutlined
                      style={{ marginRight: 4, cursor: "pointer" }}
                      onClick={() => {
                        setSelectedCustomerForPendingActions(customer);
                        fetchCustomerPendingActions(customer.id);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Add Pending Action">
                    <PlusOutlined
                      style={{ marginRight: 6, cursor: "pointer" }}
                      onClick={() => openNewCustomerPendingAction(customer)}
                    />
                  </Tooltip>
                  {customer.name} ({customer.account})
                </Tag>
              ))}
            </div>

            <div className="table-container table-container-customer-deposit">
              <Table
                columns={columns}
                dataSource={openInvoices}
                rowKey="id"
                pagination={{
                  showSizeChanger: true, // Forces the "Rows per Page" dropdown to always show
                  pageSizeOptions: ["10", "20", "50", "100"], // Optional: Customize page sizes
                  defaultPageSize: 10,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`, // Optional: Show total count
                }}
                scroll={{ y: "calc(100vh - 525px)" }}
                sticky
              />
            </div>

            <div className="total-amounts">
              <div>
                <strong>Total Allocation: </strong>
                {formatCurrency(totalAllocation)}
              </div>
              <div>
                <strong>Remaining Amount: </strong>{" "}
                {formatCurrency(remainingAmount)}
              </div>
            </div>

            <div className="fixed-form-buttons">
              <Button
                type="primary"
                onClick={() => setIsCreateInvoiceModalOpen(true)}
              >
                Create Invoice
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                disabled={false} // TODO There are some criteria ... at least one customer, check number, check amount, at least one allocation... should we disable the button or let form validation dictate?
              >
                {submitButtonLabel}
              </Button>

              <PendingActionModal
                isVisible={showCreatePendingActionModal}
                onClose={() => setShowCreatePendingActionModal(false)}
                initialValues={{
                  customerId: selectedInvoice?.customer?.id || "",
                  customerName: selectedInvoice?.customer?.name || "",
                  invoiceNumber: selectedInvoice?.invoiceNumber || undefined,
                  invoiceId: selectedInvoice?.id || undefined,
                }}
                disableClientFields={!selectedInvoice}
                onSaveComplete={() => fetchOpenInvoices()} // Optional callback to refresh actions
              />

              <PendingActionModal
                isVisible={showCreateCustomerPendingActionModal}
                onClose={() => setShowCreateCustomerPendingActionModal(false)}
                initialValues={{
                  customerId: selectedCustomerForNewPendingAction?.id || "",
                  customerName: selectedCustomerForNewPendingAction?.name || "",
                  invoiceId: null, // No invoice association for customer-level actions
                  invoiceNumber: null,
                }}
                disableClientFields={true} // Disable invoice-related fields
                onSaveComplete={() => {
                  fetchCustomerPendingActions(
                    selectedCustomerForNewPendingAction?.id
                  );
                  setShowCreateCustomerPendingActionModal(false);
                }}
              />
            </div>
          </Form>
        </Col>
      </Row>
      <Modal
        isOpen={showPendingActionsModal}
        onRequestClose={() => setShowPendingActionsModal(false)}
        contentLabel="Pending Actions"
        className="pending-actions-modal"
        overlayClassName="pending-actions-overlay"
      >
        <h2>Pending Actions for Invoice {selectedInvoice?.invoiceNumber}</h2>
        <Table
          dataSource={selectedInvoice?.pendingActions || []}
          columns={[
            { title: "Problem", dataIndex: "problem", key: "problem" },
            { title: "Status", dataIndex: "status", key: "status" },
            {
              title: "Dispute Amount",
              dataIndex: "disputeAmount",
              key: "disputeAmount",
            },
            {
              title: "Next Follow-up",
              dataIndex: "nextFollowupAt",
              key: "nextFollowupAt",
            },
          ]}
          rowKey="id"
          pagination={false}
        />
        <Button onClick={() => setShowPendingActionsModal(false)}>Close</Button>
      </Modal>

      <Modal
        isOpen={isCreateInvoiceModalOpen}
        onRequestClose={() =>
          !isSubmitting && setIsCreateInvoiceModalOpen(false)
        } // ✅ Prevent closing while submitting
        contentLabel="Create Invoice"
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
      >
        <Spin spinning={isSubmitting} tip="Creating Invoice...">
          <div className="custom-modal-header">
            <h1>Create Invoice</h1>
            <button
              className="custom-close-button"
              onClick={() =>
                !isSubmitting && setIsCreateInvoiceModalOpen(false)
              } // ✅ Prevent close if loading
              disabled={isSubmitting} // ✅ Disable close button while loading
            >
              &times;
            </button>
          </div>
          <div className="custom-modal-body">
            <div className="form-group">
              <label>
                Client <span style={{ color: "red" }}>*</span>
              </label>
              <GenericSearch
                config={entitySearchConfig.client}
                onSelectItem={handleClientSelect}
                inlineSearch={true}
                autoFocus={true}
                openModal={openClientModal}
                selectedValue={selectedClient?.name}
                disabled={isSubmitting} // ✅ Disable while loading
              />
            </div>

            <div className="form-group">
              <label>
                Customer <span style={{ color: "red" }}>*</span>
              </label>
              <Select
                placeholder="Select Customer"
                value={selectedCustomer ? selectedCustomer.id : undefined}
                onChange={(customerId) => {
                  const customer = pinnedCustomers.find(
                    (c) => c.id === customerId
                  );
                  setSelectedCustomer(customer);
                }}
                style={{ width: "100%" }}
                disabled={isSubmitting} // ✅ Disable while loading
              >
                {pinnedCustomers.map((customer) => (
                  <Select.Option key={customer.id} value={customer.id}>
                    {customer.name} ({customer.account})
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div className="form-group">
              <label>Allocate Amount</label>
              <Input
                type="number"
                value={invoiceAllocation ?? ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "" || value === "-") {
                    setInvoiceAllocation(value);
                  } else {
                    setInvoiceAllocation(parseFloat(value) || 0);
                  }
                }}
                style={{ width: "100%" }}
                disabled={isSubmitting} // ✅ Disable while loading
              />
            </div>

            <div className="form-group">
              <Checkbox
                checked={isFictitious}
                onChange={(e) => setIsFictitious(e.target.checked)}
                disabled={isSubmitting}
              >Fictitious</Checkbox>
            </div>
          </div>
          <div className="custom-modal-footer">
            <Button
              className="custom-modal-button"
              onClick={() => setIsCreateInvoiceModalOpen(false)}
              disabled={isSubmitting} // ✅ Disable while loading
            >
              Cancel
            </Button>
            <Button
              className="custom-modal-button"
              type="primary"
              onClick={handleCreateInvoice}
              loading={isSubmitting} // ✅ Show button loading state
            >
              Submit
            </Button>
          </div>
        </Spin>
      </Modal>

      <Modal
        isOpen={showCustomerPendingActionsModal}
        onRequestClose={() => setShowCustomerPendingActionsModal(false)}
        contentLabel="Customer Pending Actions"
        className="pending-actions-modal"
        overlayClassName="pending-actions-overlay"
      >
        <h2>Pending Actions for {selectedCustomerForPendingActions?.name}</h2>
        <Table
          dataSource={selectedCustomerPendingActions}
          columns={[
            { title: "Problem", dataIndex: "problem", key: "problem" },
            { title: "Status", dataIndex: "status", key: "status" },
            {
              title: "Dispute Amount",
              dataIndex: "disputeAmount",
              key: "disputeAmount",
            },
            {
              title: "Next Follow-up",
              dataIndex: "nextFollowupAt",
              key: "nextFollowupAt",
            },
          ]}
          rowKey="id"
          pagination={false}
        />
        <Button onClick={() => setShowCustomerPendingActionsModal(false)}>
          Close
        </Button>
      </Modal>
    </div>
  );
};

export default InvoiceTransactions;
