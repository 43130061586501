import React from "react";
import InvoiceTransactions from "./InvoiceTransactions";

const CustomerDepositInvoiceTransactions = () => {
  return (
    <InvoiceTransactions
      submitUrl="/deposits/"
      title="Customer Deposit"
      numberLabel="Check Number"
      amountLabel="Check Amount"
      submitButtonLabel="Submit Deposit"
    />
  );
};

export default CustomerDepositInvoiceTransactions;
