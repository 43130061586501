import React, { useState, useEffect } from 'react';
import CustomerEditForm from './CustomerEditForm';
import { updateCustomerDetails, recalculateRisk } from '../utils/apiCustomer';

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function CustomerDetailsTab({ customer, customerId, setCustomer }) {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [isRecalculating, setIsRecalculating] = useState(false);

  useEffect(() => {
    if (customer) {
      setFormData(deepCopy(customer));
      setOriginalData(deepCopy(customer));
    }
  }, [customer]);

  const handleSave = async () => {
    try {
      const updatedCustomer = await updateCustomerDetails(customerId, formData);
      setCustomer(updatedCustomer); // Update the customer state
      setFormData(updatedCustomer);
      setOriginalData(deepCopy(updatedCustomer));
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving customer details:', error);
    }
  };

  const handleCancel = () => {
    setFormData(deepCopy(originalData));
    setIsEditing(false);
  };

  // Function to trigger recalculating risk and update UI
  const handleRecalculateRisk = async () => {
    setIsRecalculating(true);
    try {
      const response = await recalculateRisk(customerId);
      setCustomer(prevCustomer => ({ ...prevCustomer, riskRating: response.riskRating })); // Update only riskRating
    } catch (error) {
      console.error('Error recalculating risk:', error);
      alert('Failed to recalculate risk.');
    } finally {
      setIsRecalculating(false);
    }
  };

  return (
    <div>
      <h2>Customer Details</h2>
      {isEditing ? (
        <CustomerEditForm
          formData={formData}
          setFormData={setFormData}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      ) : (
        <div>
          <p><strong>Name:</strong> {customer.name}</p>
          <p><strong>Account:</strong> {customer.account}</p>
          <p><strong>Email:</strong> {customer.email}</p>
          <p><strong>Phone:</strong> {customer.phone}</p>
          <p><strong>Fax:</strong> {customer.fax}</p>
          <p><strong>Email Statements:</strong> {customer.emailStatements ? 'Yes' : 'No'}</p>
          <p><strong>Fax Statements:</strong> {customer.faxStatements ? 'Yes' : 'No'}</p>
          <p><strong>Credit Limit:</strong> {customer.creditLimit}</p>
          <p><strong>Credit History:</strong> {customer.creditHistory}</p>
          <p><strong>Collections Status:</strong> {customer.collectionsStatus}</p>
          <p><strong>Risk Rating:</strong> {customer.riskRating}</p>
          <p><strong># Days Pay on Avg:</strong> {customer.daysPayOnAvg}</p>
          <button onClick={() => setIsEditing(true)}>Edit</button>
          <button onClick={handleRecalculateRisk} disabled={isRecalculating}>
            {isRecalculating ? 'Recalculating...' : 'Recalculate Risk'}
          </button>
        </div>
      )}
    </div>
  );
}

export default CustomerDetailsTab;
