import React from 'react';

function CustomerStatement({ statement }) {
  return (
    <div>
      <h3>Invoices for Statement - {statement.date}</h3>
      <table>
        <thead>
          <tr>
            <th>Invoice Number</th>
            <th>Invoice Date</th>
            <th>Due Date</th>
            <th>Amount</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {statement.invoiceSnapshots.map((invoice) => (
            <tr key={invoice.id}>
              <td>{invoice.invoiceNumber}</td>
              <td>{invoice.invoiceDate}</td>
              <td>{invoice.dueDate}</td>
              <td>
                ${invoice.amount ? Number(invoice.amount).toFixed(2) : '0.00'}
              </td>
              <td>
                ${invoice.balance ? Number(invoice.balance).toFixed(2) : '0.00'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h3>Transactions</h3>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Type</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          {statement.invoiceSnapshots.flatMap((invoice) =>
            invoice.invoiceTransactionSnapshots.map((transaction) => (
              <tr key={transaction.id}>
                <td>{transaction.date}</td>
                <td>
                  ${transaction.amount ? Number(transaction.amount).toFixed(2) : '0.00'}
                </td>
                <td>{transaction.type}</td>
                <td>{transaction.comment || 'N/A'}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}

export default CustomerStatement;
