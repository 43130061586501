import axiosInstance from '../../axiosConfig';
import config from '../../config';

export const fetchCustomer = async (customerId) => {
    const response = await axiosInstance.get(`${config.API_URL}/customers/${customerId}`);
    return response.data;
};

export const fetchClients = async () => {
    const response = await axiosInstance.get(`${config.API_URL}/clients`);
    return response.data;
};

export const updateCustomer = async (customerId, formData) => {
    const response = await axiosInstance.put(`${config.API_URL}/customers/${customerId}`, formData);
    return response.data;
};

export const fetchMetadata = async (customerId) => {
    try {
        const response = await axiosInstance.get(`${config.API_URL}/customers/${customerId}/metadata`);
        return response.data;
    } catch (error) {
        console.error('Error fetching metadata:', error);
        throw error;
    }
};

export const addCustomerNote = async (customerId, note, user) => {
    try {
      if (!user || !user.username) {
        throw new Error('User is not logged in or username is missing');
      }
      const response = await axiosInstance.post(`${config.API_URL}/customers/${customerId}/notes`, {
        message: note.message,
        author: user.username
      });
      return response.data;
    } catch (error) {
      console.error('Error adding note:', error);
      throw error;
    }
  };
  
  export const deleteCustomerNote = async (customerId, noteId) => {
    try {
      await axiosInstance.delete(`${config.API_URL}/customers/${customerId}/notes/${noteId}`);
    } catch (error) {
      console.error('Error deleting note:', error);
      throw error;
    }
  };

  export const updateCustomerDetails = async (customerId, formData) => {
    try {
      const response = await axiosInstance.put(`${config.API_URL}/customers/${customerId}/details`, formData);
      return response.data;
    } catch (error) {
      console.error('Error updating customer details:', error);
      throw error;
    }
  };
  
  export const updateCustomerContacts = async (customerId, contacts) => {
    try {
      const response = await axiosInstance.put(`${config.API_URL}/customers/${customerId}/contacts`, { contacts });
      return response.data;
    } catch (error) {
      console.error('Error updating customer contacts:', error);
      throw error;
    }
  };

  export const fetchStatements = async (customerId) => {
    try {
      const response = await axiosInstance.get(`${config.API_URL}/customers/${customerId}/statements`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching statements', error);
    }
  };

  export const generateStatement = async (customerId) => {
    try {
      const response = await axiosInstance.post(`${config.API_URL}/customers/${customerId}/generate-statement`);
      return response.data;
    } catch (error) {
      throw new Error('Error generating a statement', error);
    }
  }

  export const recalculateRisk = async (customerId) => {
    try {
        const response = await axiosInstance.post(`${config.API_URL}/customers/${customerId}/recalculate-risk`);
        return response.data;
    } catch (error) {
        console.error('Error recalculating risk:', error);
        throw error;
    }
};
