import React from 'react';

function CustomerEditForm({ formData, setFormData, handleSave, handleCancel }) {
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: newValue
    }));
  };

  return (
    <div>
      <div>
        <label>Name:</label>
        <input type="text" name="name" value={formData.name || ''} onChange={handleChange} />
      </div>
      <div>
        <label>Email:</label>
        <input type="email" name="email" value={formData.email || ''} onChange={handleChange} />
      </div>
      <div>
        <label>Phone:</label>
        <input type="text" name="phone" value={formData.phone || ''} onChange={handleChange} />
      </div>
      <div>
        <label>Fax:</label>
        <input type="text" name="fax" value={formData.fax || ''} onChange={handleChange} />
      </div>
      <div>
        <label>Email Statements:</label>
        <input type="checkbox" name="emailStatements" checked={!!formData.emailStatements} onChange={handleChange} />
      </div>
      <div>
        <label>Fax Statements:</label>
        <input type="checkbox" name="faxStatements" checked={!!formData.faxStatements} onChange={handleChange} />
      </div>
      <div>
        <label>Credit Limit:</label>
        <input type="number" name="creditLimit" value={formData.creditLimit || ''} onChange={handleChange} />
      </div>
      <div>
        <label>Credit History:</label>
        <input type="text" name="creditHistory" value={formData.creditHistory || ''} onChange={handleChange} />
      </div>
      <div>
        <label>Collections Status:</label>
        <input type="text" name="collectionsStatus" value={formData.collectionsStatus || ''} onChange={handleChange} />
      </div>
      <div>
        <label>Risk Rating:</label>
        <input type="text" name="riskRating" value={formData.riskRating || ''} onChange={handleChange} />
      </div>
      <div>
        <label># Days Pay on Avg:</label>
        <input type="number" name="daysPayOnAvg" value={formData.daysPayOnAvg || ''} onChange={handleChange} />
      </div>
      <div className="customer-actions">
        <button onClick={handleSave}>Save</button>
        <button onClick={handleCancel}>Cancel</button>
      </div>
    </div>
  );
}

export default CustomerEditForm;
