import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import {
  Card,
  Form,
  Input,
  DatePicker,
  List,
  Statistic,
  Row,
  Col,
  Button,
  notification,
  Typography,
  Table,
  Select,
} from "antd";
import axiosInstance from "../axiosConfig";
import config from "../config";
import GenericSearchWithModalWrapper from "./GenericSearchWithModalWrapper";
import entitySearchConfig from "../entitySearchConfig";
import "./CallCustomerDashboard.css"; // Optional CSS override file

Modal.setAppElement("#root");

const { Title, Text } = Typography;

export default function CallCustomerSummary() {
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [totalOpenInvoices, setTotalOpenInvoices] = useState(0);
  const [avgRiskScore, setAvgRiskScore] = useState(0);
  const [totalAmountOwed, setTotalAmountOwed] = useState(0);
  const [totalPositiveAmountOwed, setTotalPositiveAmountOwed] = useState(0);
  const [arAgingData, setArAgingData] = useState([]);
  const [riskTierData, setRiskTierData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [qualifyingCustomers, setQualifyingCustomers] = useState(0);

  const [minRisk, setMinRisk] = useState(5);
  const [selectedClient, setSelectedClient] = useState(null);

  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [remainingInQueue, setRemainingInQueue] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const [viewingInvoice, setViewingInvoice] = useState(null);

  const [selectedInvoiceRowKeys, setSelectedInvoiceRowKeys] = useState([]);
  const [selectedInvoiceTotals, setSelectedInvoiceTotals] = useState({
    amount: 0,
    balance: 0,
  });

  const [showCreatePendingActionModal, setShowCreatePendingActionModal] =
    useState(false);
  const [showCallResultModal, setShowCallResultModal] = useState(false);

  const { Option } = Select;

  const formatCurrency = (amount) =>
    `$${Number(amount).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    })}`;

  const fetchNextCustomer = async (direction = "next") => {
    try {
      setLoading(true);

      const params = {
        minRisk,
        direction,
        ...(selectedClient?.id && { clientId: selectedClient.id }),
        currentCustomerId: currentCustomer?.id || null,
      };

      const response = await axiosInstance.get(
        `${config.API_URL}/call-customer/get-next-customer-to-call`,
        { params }
      );

      const { customer, remainingInQueue, currentIndex } = response.data;

      if (customer) {
        setCurrentCustomer(customer);
        setRemainingInQueue(remainingInQueue);
        setCurrentIndex(currentIndex);
      } else {
        notification.info({
          message: "No Eligible Customers",
          description: "There are no more customers available for calling.",
        });
      }
    } catch (err) {
      console.error(err);
      notification.error({
        message: "Error Fetching Customer",
        description: err.response?.data?.error || "Unexpected error occurred.",
      });
    } finally {
      setLoading(false);
    }
  };

  const mockInvoices = [
    {
      id: 1,
      invoiceNumber: "INV-1001",
      date: "2025-03-01",
      dueDate: "2025-03-15",
      amount: 1200.0,
      balance: 1200.0,
      status: "Open",
    },
    {
      id: 2,
      invoiceNumber: "INV-1002",
      date: "2025-02-15",
      dueDate: "2025-03-01",
      amount: 750.0,
      balance: 0.0,
      status: "Paid",
    },
    {
      id: 3,
      invoiceNumber: "INV-1003",
      date: "2025-03-05",
      dueDate: "2025-03-20",
      amount: 850.5,
      balance: 250.5,
      status: "Partially Paid",
    },
  ];

  const mockInvoiceHistory = [
    {
      id: 1,
      date: "2025-03-01",
      description: "Invoice created with balance $1200.00",
    },
    {
      id: 2,
      date: "2025-03-05",
      description: "Partial payment received: $400.00",
    },
    {
      id: 3,
      date: "2025-03-10",
      description: "Status changed to 'Partially Paid'",
    },
  ];

  const mockPendingItems = [
    {
      id: 1,
      date: "2025-03-15",
      problem: "Customer disputed invoice INV-1003 due to incorrect charges.",
    },
    {
      id: 2,
      date: "2025-03-20",
      problem: "Awaiting response from customer on payment plan options.",
    },
  ];

  const mockCallResults = [
    {
      id: 1,
      callCode: "Initial Contact",
      callDate: "2025-03-20",
      willPayBy: "2025-04-01",
      comments: "Customer promised to pay after payroll clears.",
      loggedBy: "Admin",
    },
    {
      id: 2,
      callCode: "Follow-Up",
      callDate: "2025-03-25",
      willPayBy: "2025-04-05",
      comments: "Left voicemail, waiting to hear back.",
      loggedBy: "Agent 1",
    },
  ];

  // const handleRecalculateRisk = async () => {
  //   setRecalculating(true);
  //   try {
  //     // Simulate processing delay
  //     await new Promise((resolve) => setTimeout(resolve, 2000));

  //     notification.success({
  //       message: "Risk Recalculated",
  //       description: "Customer risk scores were successfully recalculated.",
  //     });

  //     // TODO: trigger data refresh here
  //   } catch (err) {
  //     notification.error({
  //       message: "Error Recalculating Risk",
  //       description: "An error occurred while recalculating customer risk.",
  //     });
  //   } finally {
  //     setRecalculating(false);
  //   }
  // };

  useEffect(() => {
    // Parse numeric range from riskTierData and sum qualifying customers
    let count = 0;
    for (const tier of riskTierData) {
      const match = tier.tier.match(/(\d+)(?:–(\d+)|\+)?/);
      if (match) {
        const upper = match[2] ? parseInt(match[2], 10) : Infinity;
        if (upper >= minRisk) {
          count += tier.customers;
        }
      }
    }
    setQualifyingCustomers(count);
  }, [minRisk, riskTierData]);

  useEffect(() => {
    const fetchARAgingBuckets = async () => {
      try {
        const response = await axiosInstance.get(
          `${config.API_URL}/call-customer/ar-aging-buckets`,
          { params: selectedClient ? { clientId: selectedClient.id } : {} }
        );
        setArAgingData(response.data);
      } catch (err) {
        console.error(err);
        notification.error({
          message: "Failed to load AR Aging Buckets",
          description: err.message || "An unexpected error occurred.",
        });
      }
    };

    fetchARAgingBuckets();
  }, [selectedClient]);

  useEffect(() => {
    if (!arAgingData.length) return;

    const totalOwed = arAgingData.reduce((sum, row) => {
      const raw = parseFloat(row.amountOwed?.replace(/[^\d.-]/g, "") || 0);
      return sum + raw;
    }, 0);

    const positiveOwed = arAgingData.reduce((sum, row) => {
      const raw = parseFloat(
        row.positiveBalances?.replace(/[^\d.-]/g, "") || 0
      );
      return sum + raw;
    }, 0);

    setTotalAmountOwed(totalOwed);
    setTotalPositiveAmountOwed(positiveOwed);
  }, [arAgingData]);

  useEffect(() => {
    if (!arAgingData.length) return;

    let totalOwed = 0;
    let positiveOwed = 0;
    let invoiceCount = 0;

    for (const row of arAgingData) {
      const amount = parseFloat(row.amountOwed?.replace(/[^\d.-]/g, "") || 0);
      const positive = parseFloat(
        row.positiveBalances?.replace(/[^\d.-]/g, "") || 0
      );
      const count = parseInt(row.invoiceCount || 0);

      totalOwed += amount;
      positiveOwed += positive;
      invoiceCount += count;
    }

    setTotalAmountOwed(totalOwed);
    setTotalPositiveAmountOwed(positiveOwed);
    setTotalOpenInvoices(invoiceCount);
  }, [arAgingData]);

  useEffect(() => {
    const fetchRiskTiers = async () => {
      try {
        const response = await axiosInstance.get(
          `${config.API_URL}/call-customer/risk-tiers`,
          { params: selectedClient ? { clientId: selectedClient.id } : {} }
        );
        setRiskTierData(response.data);
      } catch (err) {
        console.error(err);
        notification.error({
          message: "Failed to load Risk Tiers",
          description: err.message || "An unexpected error occurred.",
        });
      }
    };

    fetchRiskTiers();
  }, [selectedClient]);

  useEffect(() => {
    if (!riskTierData.length) return;

    let totalCustomers = 0;
    let weightedRiskSum = 0;

    for (const tier of riskTierData) {
      const match = tier.tier.match(/(\d+)(?:–(\d+)|\+)?/);
      if (match) {
        const min = parseInt(match[1], 10);
        const max = match[2] ? parseInt(match[2], 10) : min;
        const midpoint = match[2] ? (min + max) / 2 : min;

        totalCustomers += tier.customers;
        weightedRiskSum += midpoint * tier.customers;
      }
    }

    setTotalCustomers(totalCustomers);
    setAvgRiskScore(
      totalCustomers > 0 ? Math.round(weightedRiskSum / totalCustomers) : 0
    );
  }, [riskTierData]);

  const riskTierColumns = [
    { title: "Risk Tier", dataIndex: "tier", key: "tier" },
    { title: "Customers", dataIndex: "customers", key: "customers" },
    {
      title: "Total Owed",
      dataIndex: "totalOwed",
      key: "totalOwed",
      render: (value) =>
        formatCurrency(parseFloat(value?.replace(/[^\d.-]/g, "") || 0)),
    },
  ];

  const riskTierOptions = [
    { label: "Very Low (0–4)", minScore: 0 },
    { label: "Low (5–9)", minScore: 5 },
    { label: "Elevated Low (10–14)", minScore: 10 },
    { label: "Moderate (15–39)", minScore: 15 },
    { label: "High (40–99)", minScore: 40 },
    { label: "Very High (100–179)", minScore: 100 },
    { label: "Critical (180+)", minScore: 180 },
  ];

  const arAgingColumns = [
    { title: "Age", dataIndex: "age", key: "age" },
    {
      title: "Invoices",
      dataIndex: "invoiceCount",
      key: "invoiceCount",
    },

    {
      title: "Amount Owed",
      dataIndex: "amountOwed",
      key: "amountOwed",
      render: (value) =>
        formatCurrency(parseFloat(value?.replace(/[^\d.-]/g, "") || 0)),
    },
    {
      title: "Cumulative %",
      dataIndex: "cumulativePercent",
      key: "cumulativePercent",
    },
    {
      title: "Positive Balances Owed",
      dataIndex: "positiveBalances",
      key: "positiveBalances",
      render: (value) =>
        formatCurrency(parseFloat(value?.replace(/[^\d.-]/g, "") || 0)),
    },
    {
      title: "Cumulative Revenue",
      dataIndex: "cumulativeRevenue",
      key: "cumulativeRevenue",
      render: (value) =>
        formatCurrency(parseFloat(value?.replace(/[^\d.-]/g, "") || 0)),
    },
    { title: "Revenue %", dataIndex: "revenuePercent", key: "revenuePercent" },
  ];

  const invoiceColumns = [
    { title: "Invoice #", dataIndex: "invoiceNumber", key: "invoiceNumber" },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Due Date", dataIndex: "dueDate", key: "dueDate" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Open", value: "Open" },
        { text: "Paid", value: "Paid" },
        { text: "Partially Paid", value: "Partially Paid" },
      ],
      defaultFilteredValue: ["Open"],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (val) =>
        `$${Number(val).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}`,
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (val) =>
        `$${Number(val).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })}`,
    },
    {
      title: "Details",
      render: (_, record) => (
        <Button size="small" onClick={() => setViewingInvoice(record)}>
          View
        </Button>
      ),
    },
  ];

  const pendingActionColumns = [
    {
      title: "Issue",
      dataIndex: "problem",
      key: "problem",
    },
    {
      title: "Disposition",
      dataIndex: "disposition",
      key: "disposition",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (text) => text && new Date(text).toLocaleDateString(),
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      key: "assignedTo",
    },
    {
      title: "Follow-Up Date",
      dataIndex: "followUpDate",
      key: "followUpDate",
      render: (text) => text && new Date(text).toLocaleDateString(),
    },
  ];

  const callResultsColumns = [
    { title: "Call Code", dataIndex: "callCode", key: "callCode" },
    { title: "Call Date", dataIndex: "callDate", key: "callDate" },
    { title: "Will Pay By Date", dataIndex: "willPayBy", key: "willPayBy" },
    { title: "Comments", dataIndex: "comments", key: "comments" },
    { title: "Logged By", dataIndex: "loggedBy", key: "loggedBy" },
  ];

  if (currentCustomer) {
    const rowSelection = {
      selectedRowKeys: selectedInvoiceRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedInvoiceRowKeys(selectedRowKeys);

        const totals = selectedRows.reduce(
          (acc, row) => {
            acc.amount += Number(row.amount || 0);
            acc.balance += Number(row.balance || 0);
            return acc;
          },
          { amount: 0, balance: 0 }
        );

        setSelectedInvoiceTotals(totals);
      },
    };

    return (
      <div className="flexDiv" style={{ padding: 0 }}>
        <Title level={2} style={{ margin: 10 }}>
          📞 Call Customer: {currentCustomer.name}
        </Title>

        {/* Top control row */}
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: 16 }}
          wrap={false}
        >
          <Col flex="auto">
            <Row gutter={12}>
              <Col>
                <strong>Minimum Risk Tier:</strong> {minRisk}
              </Col>
              <Col>
                <strong>Client:</strong> {selectedClient?.name || "N/A"}
              </Col>
              <Col>
                <strong>Remaining in Queue:</strong> {remainingInQueue ?? "N/A"}
              </Col>
            </Row>
          </Col>
          <Col>
            <Row gutter={8}>
              <Col>
                <Button
                  disabled={!currentCustomer || currentIndex <= 0}
                  onClick={() => fetchNextCustomer("prev")}
                >
                  ← Go Back
                </Button>
              </Col>
              <Col>
                <Button
                  disabled={remainingInQueue <= 1}
                  onClick={() => fetchNextCustomer("next")}
                  type="primary"
                >
                  Get Next →
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    setCurrentCustomer(null);
                    setRemainingInQueue(null);
                    setCurrentIndex(null);
                  }}
                >
                  🏠 Return to Dashboard
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Customer details row */}
        <Row gutter={[12, 12]} style={{ marginBottom: 16 }}>
          <Col span={8}>
            <strong>Name:</strong> {currentCustomer.name}
          </Col>
          <Col span={8}>
            <strong>Contact Name:</strong>{" "}
            {currentCustomer.contactName || "N/A"}
          </Col>
          <Col span={8}>
            <strong>Account Number:</strong> {currentCustomer.account}
          </Col>
          <Col span={8}>
            <strong>Phone Number:</strong>{" "}
            {currentCustomer.phoneNumber || "N/A"}
          </Col>
          <Col span={8}>
            <strong>Risk Score:</strong> {currentCustomer.riskRating}
          </Col>
          <Col span={8}>
            <strong>Credit Limit:</strong>{" "}
            {currentCustomer.creditLimit
              ? `$${currentCustomer.creditLimit.toLocaleString()}`
              : "N/A"}
          </Col>
        </Row>

        <Card
          size="small"
          title={
            <Row
              justify="space-between"
              align="middle"
              style={{ width: "100%" }}
            >
              <Col>📄 Invoices</Col>
              <Col>
                <Button size="small" type="primary">
                  Email Delinquent Invoices
                </Button>
              </Col>
            </Row>
          }
          style={{ marginBottom: 16 }}
        >
          <Table
            dataSource={mockInvoices}
            columns={invoiceColumns}
            rowKey="id"
            pagination={false}
            size="small"
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={5}>
                  <strong>Selected Totals</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={1}>
                  {formatCurrency(selectedInvoiceTotals.amount)}
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={1}>
                  {formatCurrency(selectedInvoiceTotals.balance)}
                </Table.Summary.Cell>
                <Table.Summary.Cell colSpan={1} />
              </Table.Summary.Row>
            )}
          />
        </Card>

        <Modal
          open={!!viewingInvoice}
          onCancel={() => setViewingInvoice(null)}
          title={`Invoice ${viewingInvoice?.invoiceNumber} History`}
          footer={null}
        >
          <List
            dataSource={mockInvoiceHistory}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={item.date}
                  description={item.description}
                />
              </List.Item>
            )}
          />
        </Modal>

        <Card
          size="small"
          title={
            <Row
              justify="space-between"
              align="middle"
              style={{ width: "100%" }}
            >
              <Col>🚩 Pending Items</Col>
              <Col>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => setShowCreatePendingActionModal(true)}
                >
                  Create Pending Action
                </Button>
              </Col>
            </Row>
          }
          style={{ marginBottom: 16 }}
        >
          <Table
            dataSource={mockPendingItems}
            columns={pendingActionColumns}
            size="small"
            rowKey="id"
            pagination={false}
          />
        </Card>

        <Card
          size="small"
          title={
            <Row
              justify="space-between"
              align="middle"
              style={{ width: "100%" }}
            >
              <Col>📞 Call Results</Col>
              <Col>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => setShowCallResultModal(true)}
                >
                  Record Call Result
                </Button>
              </Col>
            </Row>
          }
          style={{ marginBottom: 16 }}
        >
          <Table
            dataSource={mockCallResults}
            columns={callResultsColumns}
            size="small"
            rowKey="id"
            pagination={false}
          />
        </Card>

        <Modal
          title="Create Pending Action"
          open={showCreatePendingActionModal}
          onCancel={() => setShowCreatePendingActionModal(false)}
          onOk={() => {
            // TODO: Submit logic
            setShowCreatePendingActionModal(false);
          }}
        >
          <Form layout="vertical" onFinish={(values) => console.log(values)}>
            <Form.Item
              label="Problem Description"
              name="problem"
              rules={[{ required: true }]}
            >
              <Input.TextArea rows={2} />
            </Form.Item>
            <Form.Item label="Invoice (optional)" name="invoice">
              <Input />
            </Form.Item>
            <Form.Item label="Follow-Up Date" name="dueDate">
              <DatePicker />
            </Form.Item>
            <Form.Item label="Assigned To" name="assignedTo">
              <Input />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Record Call Result"
          open={showCallResultModal}
          onCancel={() => setShowCallResultModal(false)}
          onOk={() => setShowCallResultModal(false)}
        >
          <Form layout="vertical" onFinish={(values) => console.log(values)}>
            <Form.Item
              label="Call Code"
              name="callCode"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Call Date" name="callDate">
              <DatePicker />
            </Form.Item>
            <Form.Item label="Will Pay By Date" name="willPayBy">
              <DatePicker />
            </Form.Item>
            <Form.Item label="Comments" name="comments">
              <Input.TextArea rows={2} />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }

  return (
    <div className="flexDiv">
      <Title level={2} style={{ marginBottom: 10 }}>
        📊 Call Customer Dashboard
      </Title>

      <Card size="small" title="🎯 Start Calling" style={{ marginBottom: 10 }}>
        <Row align="middle" gutter={12} wrap={false}>
          {/* Client Search - Expands to fill remaining space */}
          <Col flex="auto">
            <GenericSearchWithModalWrapper
              config={entitySearchConfig.client}
              selectedItem={selectedClient}
              onSelectItem={setSelectedClient}
              label="Filter by Client (Optional)"
              disabled={loading}
            />
          </Col>

          {/* Minimum Risk Tier - Natural Width */}
          <Col flex="none">
            <div style={{ marginBottom: 0 }}>
              <Text>Select Minimum Risk Tier:</Text>
            </div>
            <Select
              value={minRisk}
              onChange={(value) => setMinRisk(value)}
              style={{ width: 240 }}
              disabled={loading}
            >
              {riskTierOptions.map((opt) => (
                <Option key={opt.minScore} value={opt.minScore}>
                  {opt.label}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Eligible Customers & Get Next - Natural Width */}
          <Col flex="none">
            <div style={{ marginBottom: 0 }}>
              <Text>
                Eligible Customers: <strong>{qualifyingCustomers}</strong>
              </Text>
            </div>
            <Button
              type="primary"
              loading={loading}
              disabled={loading}
              onClick={fetchNextCustomer}
            >
              Get Next 🔔
            </Button>
          </Col>
        </Row>
      </Card>

      <Row gutter={12} style={{ marginBottom: 10 }} wrap={false}>
        <Col flex="1 1 16%">
          <Card className="compact-card">
            <Statistic title="Total Customers" value={totalCustomers} />
          </Card>
        </Col>
        <Col flex="1 1 16%">
          <Card className="compact-card">
            <Statistic title="Open Invoices" value={totalOpenInvoices} />
          </Card>
        </Col>
        <Col flex="1 1 16%">
          <Card className="compact-card">
            <Statistic
              title="Avg. Risk Score"
              value={avgRiskScore}
              suffix="/ 1000"
            />
          </Card>
        </Col>
        <Col flex="1 1 16%">
          <Card className="compact-card">
            <Statistic
              title="Total Amount Owed"
              value={formatCurrency(totalAmountOwed)}
            />
          </Card>
        </Col>
        <Col flex="1 1 16%">
          <Card className="compact-card">
            <Statistic
              title="Total Positive Amount Owed"
              value={formatCurrency(totalPositiveAmountOwed)}
            />
          </Card>
        </Col>
      </Row>

      <Card
        size="small"
        title="💸 AR Aging Buckets"
        style={{ marginBottom: 10 }}
      >
        <Table
          dataSource={arAgingData}
          columns={arAgingColumns}
          pagination={false}
          size="small"
          rowClassName={() => "compact-row"}
          rowKey="key"
        />
      </Card>

      <Card
        size="small"
        className="compact-card"
        title={
          <Row justify="space-between" align="middle" style={{ width: "100%" }}>
            <Col>📊 Customers by Risk Tier</Col>
            {/* <Col>
              <Button
                size="small"
                loading={recalculating}
                onClick={handleRecalculateRisk}
              >
                Recalculate Customer Risk
              </Button>
            </Col> */}
          </Row>
        }
        style={{ marginBottom: 8 }}
      >
        <Table
          dataSource={riskTierData}
          columns={riskTierColumns}
          pagination={false}
          size="small"
          rowClassName={() => "compact-row"}
          rowKey="tier"
          components={{
            body: {
              row: (props) => <tr {...props} style={{ height: 28 }} />,
            },
          }}
        />
      </Card>
    </div>
  );
}
