import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { Input, Table, Select } from "antd";
import axiosInstance from "../axiosConfig";
import debounce from "lodash.debounce";
import { useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners"; // Import the spinner
import "./GenericSearch.css";

function GenericSearch({
  config,
  onSelectItem,
  closeModal,
  inModal,
  initialFormData = {},
  inlineSearch = false,
  selectedValue,
  autoFocus = false,
  openModal,
  disabled = false,
}) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialPage = parseInt(searchParams.get("page"), 10) || 1;

  const [formData, setFormData] = useState({ ...initialFormData });
  const [results, setResults] = useState([]);
  const [inlineResults, setInlineResults] = useState([]);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const [metadata, setMetadata] = useState([]);
  const [inlineSearchValue, setInlineSearchValue] = useState(
    selectedValue || ""
  );
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [searching, setSearching] = useState(false);
  const [resultClicked, setResultClicked] = useState(false);
  const [defaultPageSize] = useState(20)
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [renderedPageSize, setRenderedPageSize] = useState(defaultPageSize); // used by <Table />

  const [totalItems, setTotalItems] = useState(0);

  const [focusedFieldName, setFocusedFieldName] = useState(null); // Track the name of the last focused field
  const inputRefs = useRef({}); // Store refs for all input fields

  const { Option } = Select;

  // Save the field name when focusing on an input
  const handleFocus = (name) => {
    setFocusedFieldName(name);
  };

  // Restore focus after the search is completed
  useEffect(() => {
    if (!searching && focusedFieldName && inputRefs.current[focusedFieldName]) {
      inputRefs.current[focusedFieldName].focus();
    }
  }, [searching, focusedFieldName]);

  const inlineResultsRef = useRef(null);
  const searchInputRef = useRef(null);

  const { entity, metadataEndpoint, searchEndpoint, title } = config;

  const memoizedSearchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const searchNow = useCallback(
    async (page, data, sortField, sortOrder, limit) => {
      setSearching(true);
      try {
        const response = await axiosInstance.get(searchEndpoint, {
          params: {
            ...data,
            page,
            limit,
            sortField,
            sortOrder,
          },
        });

        const items = response.data.items || response.data[entity];
        setResults(items || []);
        setCurrentPage(response.data.currentPage || 1);
        setTotalItems(response.data.totalItems || 0);
        setRenderedPageSize(limit);
        setError("");
      } catch (err) {
        console.error(`Error searching ${entity}:`, err);
        setError(`Error searching ${entity}. Please try again later.`);
      } finally {
        setSearching(false);
      }
    },
    [searchEndpoint, entity]
  );

  const handleSearch = useMemo(
    () =>
      debounce(async (page, data, sortField, sortOrder, limit) => {
        setSearching(true);
        try {
          const response = await axiosInstance.get(searchEndpoint, {
            params: {
              ...data,
              page,
              limit,
              sortField,
              sortOrder,
            },
          });
          const items = response.data.items || response.data[entity];
          setResults(items || []);
          setCurrentPage(response.data.currentPage || 1);
          setTotalItems(response.data.totalItems || 0);
          setRenderedPageSize(limit);
          setError("");
        } catch (err) {
          console.error(`Error searching ${entity}:`, err);
          setError(`Error searching ${entity}. Please try again later.`);
        } finally {
          setSearching(false);
        }
      }, 500),
    [searchEndpoint, entity]
  );

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = await axiosInstance.get(metadataEndpoint);
        console.log("Metadata fetched:", response.data); // Log the fetched metadata
        setMetadata(response.data);

        if (response.data && response.data.searchFields) {
          const initialFormData = response.data.searchFields.reduce(
            (acc, field) => {
              acc[field.name] = memoizedSearchParams.get(field.name) || "";
              return acc;
            },
            {}
          );
          setFormData((prevFormData) => ({
            ...initialFormData,
            ...prevFormData,
          }));

          // Default sort
          const defaultSortField =
            response.data.displayFields?.find((f) => f.defaultSort)?.name ||
            null;
          const defaultSortOrder =
            response.data.displayFields?.find((f) => f.defaultSort)
              ?.defaultSortOrder || "asc";

              if (defaultSortField) {
                setSortField(defaultSortField);
                setSortOrder(defaultSortOrder);
                searchNow(1, { ...initialFormData }, defaultSortField, defaultSortOrder, defaultPageSize);
              } else {
                searchNow(1, { ...initialFormData }, null, null, defaultPageSize);
              }
        } else {
          console.error("Metadata searchFields are missing or empty.");
        }
      } catch (err) {
        console.error(`Error fetching metadata for ${entity}:`, err);
      }
    };
    fetchMetadata();
  }, [metadataEndpoint, entity, memoizedSearchParams, defaultPageSize, searchNow]);

  const handleChange = (e) => {
    const updatedFormData = {
      ...formData,
      [e.target.name]: e.target.value,
    };
    setFormData(updatedFormData);
    setCurrentPage(1);
    handleSearch(1, updatedFormData, sortField, sortOrder, pageSize);
  };

  const debouncedInlineSearchFunction = useMemo(
    () =>
      debounce(async (value) => {
        if (!value.trim()) {
          setInlineResults([]);
          setSearching(false);
          return;
        }

        setSearching(true);
        try {
          const searchField = metadata.searchFields[0].name || "name";
          console.log(
            "Searching with field:",
            searchField,
            "and value:",
            value
          );

          const response = await axiosInstance.get(searchEndpoint, {
            params: {
              [searchField]: value,
              limit: 5,
              sortField,
              sortOrder,
            },
          });

          const items = response.data.items || response.data[entity];
          setInlineResults(items || []);
          setHighlightedIndex(0);
          setResultClicked(false);
        } catch (err) {
          console.error(`Error performing inline search for ${entity}:`, err);
          setInlineResults([]);
        }
        setSearching(false);
      }, 500),
    [searchEndpoint, entity, metadata, sortField, sortOrder]
  );

  const debouncedInlineSearch = useCallback(
    (value) => {
      debouncedInlineSearchFunction(value);
    },
    [debouncedInlineSearchFunction]
  );

  const handleInlineChange = (e) => {
    const value = e.target.value;
    setInlineSearchValue(value);
    debouncedInlineSearch(value);

    if (!value.trim() && onSelectItem) {
      onSelectItem({ id: "", name: "" });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) =>
        Math.min(prevIndex + 1, inlineResults.length - 1)
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (highlightedIndex >= 0 && highlightedIndex < inlineResults.length) {
        handleResultClick(inlineResults[highlightedIndex]);
      }
    } else if (e.key === "Escape") {
      setInlineResults([]);
    }
  };

  useEffect(() => {
    if (
      inlineResultsRef.current &&
      highlightedIndex >= 0 &&
      highlightedIndex < inlineResults.length
    ) {
      const highlightedItem =
        inlineResultsRef.current.children[highlightedIndex];
      if (highlightedItem) {
        highlightedItem.scrollIntoView({ block: "nearest" });
      }
    }
  }, [highlightedIndex, inlineResults]);

  const handleResultClick = (item) => {
    if (onSelectItem) {
      onSelectItem(item);
      setInlineSearchValue(item.name);
      setInlineResults([]);
      setSearching(false);
      setResultClicked(true);
      if (inModal) {
        setResults([]);
        handleModalClose();
      }
    }
  };

  useEffect(() => {
    if (selectedValue) {
      setInlineSearchValue(selectedValue);
      setResultClicked(true);
      setInlineResults([]);
    }
  }, [selectedValue]);

  const handleModalClose = () => {
    closeModal && closeModal();
  };

  if (
    !metadata ||
    !metadata.searchFields ||
    metadata.searchFields.length === 0
  ) {
    return <div>Loading...</div>;
  }

  const renderAntdTable = () => {
    const defaultSortField = metadata.displayFields?.find(
      (f) => f.defaultSort
    )?.name;
    const defaultSortOrder =
      metadata.displayFields?.find((f) => f.defaultSort)?.defaultSortOrder ||
      "asc";

    const columns = (metadata.displayFields || []).map((field) => ({
      title: field.label,
      dataIndex: field.name,
      key: field.name,
      sorter: field.sortable === true,
      defaultSortOrder:
        field.name === defaultSortField
          ? defaultSortOrder === "asc"
            ? "ascend"
            : "descend"
          : undefined,
      render: (value, record) => {
        if (field.type === "array" && Array.isArray(value)) {
          return value
            .map((subItem) =>
              (field.subFields || [])
                .map((subField) => subItem[subField.name] || "N/A")
                .join(", ")
            )
            .join("\n");
        }
        return value || "N/A";
      },
    }));

    return (
      <div className="table-wrapper">
        <Table
          rowKey="id"
          loading={{
            spinning: searching,
            indicator: <ClipLoader color="#007bff" size={40} />,
          }}
          columns={columns}
          dataSource={results}
          pagination={{
            current: currentPage,
            pageSize: renderedPageSize,
            total: totalItems,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          onChange={(pagination, filters, sorter) => {
            const newPageSize = pagination.pageSize;
            const pageSizeChanged = newPageSize !== pageSize;
          
            const newSortField = sorter.order ? sorter.field : null;
            const newSortOrder =
              sorter.order === "ascend"
                ? "asc"
                : sorter.order === "descend"
                ? "desc"
                : null;
          
            if (pageSizeChanged) {
              setPageSize(newPageSize); // for use in handleChange, etc.
              setCurrentPage(1);
              handleSearch(1, formData, newSortField, newSortOrder, newPageSize);
            } else {
              setCurrentPage(pagination.current);
              handleSearch(pagination.current, formData, newSortField, newSortOrder, newPageSize);
            }
          
            setSortField(newSortField);
            setSortOrder(newSortOrder);
          }}
          
          onRow={(record) => ({
            onClick: () => handleResultClick(record),
          })}
          rowClassName={() => "clickable-row"}
          scroll={{ y: "calc(100vh - 400px)" }} // adjust to fit your layout
          sticky
        />
      </div>
    );
  };

  if (inlineSearch) {
    return (
      <div className="inline-search">
        <div className="inline-search-input-container">
          <Input
            type="text"
            placeholder={`Search by ${
              metadata?.searchFields[0]?.label || "name"
            }`}
            value={inlineSearchValue}
            onChange={handleInlineChange}
            onKeyDown={handleKeyDown}
            ref={searchInputRef}
            autoFocus={autoFocus}
            disabled={disabled}
          />
          <button
            type="button"
            className="inline-search-button"
            onClick={() => {
              openModal();
            }}
            tabIndex={-1}
            disabled={disabled}
          >
            <i className="fas fa-search"></i>
          </button>
        </div>
        <ul
          className={`inline-results ${
            inlineResults.length > 0 || (inlineSearchValue && !resultClicked)
              ? "visible"
              : ""
          }`}
          ref={inlineResultsRef}
        >
          {searching ? (
            <li className="searching">Searching...</li>
          ) : inlineResults.length > 0 ? (
            inlineResults.map((item, index) => (
              <li
                key={item.id}
                onClick={() => handleResultClick(item)}
                className={`inline-result-item ${
                  index === highlightedIndex ? "highlighted" : ""
                }`}
              >
                {item[metadata?.searchFields?.[0]?.name || "N/A"]}
              </li>
            ))
          ) : (
            inlineSearchValue &&
            !searching && <li className="no-results">No results found</li>
          )}
        </ul>
      </div>
    );
  } else if (inModal) {
    return (
      <div className="search-modal">
        <div className={"search-modal-content"}>
          <div className="modal-header">
            <h1>{title || `${entity} Search`}</h1>
            <button className="close-button" onClick={handleModalClose}>
              ×
            </button>
          </div>

          {/* Main search content */}
          <div className="generic-search">
            <div className="search-fields">
              {metadata.searchFields.map((field) => (
                <div key={field.name}>
                  <label>
                    {field.label}:
                    {field.type === "select" &&
                    Array.isArray(field.enumValues) ? (
                      <Select
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        showSearch
                        allowClear
                        placeholder={`Select ${field.label}`}
                        name={field.name}
                        value={formData[field.name] || undefined}
                        onChange={(value) =>
                          handleChange({ target: { name: field.name, value } })
                        }
                        style={{ width: "100%" }}
                        disabled={searching}
                        optionFilterProp="children"
                      >
                        {field.enumValues.map((opt) => (
                          <Option key={opt} value={opt}>
                            {opt}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Input
                        type={field.type || "text"}
                        name={field.name}
                        value={formData[field.name] || ""}
                        onChange={handleChange}
                        onFocus={() => handleFocus(field.name)}
                        ref={(el) => (inputRefs.current[field.name] = el)}
                        autoComplete="new-password"
                        disabled={searching}
                      />
                    )}
                  </label>
                </div>
              ))}
            </div>
            {error && <p>{error}</p>}

            {/* Table and pagination content */}
            {renderAntdTable()}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="generic-search">
        <div className="page-header">
          <h1>{title || `${entity} Search`}</h1>
        </div>

        <div className="search-fields">
          {metadata.searchFields.map((field) => (
            <div key={field.name}>
              <label>
                {field.label}:
                <input
                  type={field.type || "text"}
                  name={field.name}
                  value={formData[field.name] || ""}
                  onChange={handleChange}
                  onFocus={() => handleFocus(field.name)}
                  ref={(el) => (inputRefs.current[field.name] = el)}
                  autoComplete="new-password"
                  disabled={searching} // Disable inputs during search
                />
              </label>
            </div>
          ))}
        </div>

        {error && <p>{error}</p>}

        {renderAntdTable()}
      </div>
    );
  }
}

export default GenericSearch;
