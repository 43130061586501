import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Button,
  Select,
  Row,
  Col,
  notification,
  Drawer,
} from "antd";
import moment from "moment";
import axiosInstance from "../axiosConfig";
import config from "../config";

const StatementsScreen = () => {
  const currentMonth = moment().month() + 1; // Get current month (1-based)
  const currentYear = moment().year(); // Get current year

  const [filteredRowCount, setFilteredRowCount] = useState(0);
  const [totalStatements, setTotalStatements] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedStatement, setSelectedStatement] = useState(null);
  const [filteredStatements, setFilteredStatements] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10, // Default page size
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
  });

  const handleTableChange = (
    paginationConfig,
    filters,
    sorter,
    { currentDataSource }
  ) => {
    setPagination(paginationConfig);
    setFilteredRowCount(currentDataSource.length);
  };

  const handleDeleteStatements = async () => {
    if (selectedRowKeys.length === 0) {
      notification.warning({ message: "No statements selected for deletion" });
      return;
    }

    try {
      await axiosInstance.delete(`${config.API_URL}/statements`, {
        data: { statementIds: selectedRowKeys },
      });

      notification.success({
        message: `Deleted ${selectedRowKeys.length} statements successfully.`,
      });

      // Remove deleted statements from UI
      setFilteredStatements((prev) =>
        prev.filter((stmt) => !selectedRowKeys.includes(stmt.id))
      );

      // Clear selection after deletion
      setSelectedRowKeys([]);
    } catch (error) {
      console.error("Error deleting statements:", error);
      notification.error({
        message: "Failed to delete statements. Please try again.",
      });
    }
  };

  const handleGenerateStatements = async () => {
    if (!selectedMonth || !selectedYear) {
      notification.warning({
        message:
          "Please select both Month and Year before generating statements.",
      });
      return;
    }

    // Prevent generating statements for future months/years
    if (
      selectedYear > currentYear ||
      (selectedYear === currentYear && selectedMonth > currentMonth)
    ) {
      notification.warning({
        message: "You cannot generate statements for a future period.",
      });
      return;
    }

    setIsGenerating(true);
    try {
      const response = await axiosInstance.post(
        `${config.API_URL}/statements/generate/${selectedYear}/${selectedMonth}`
      );

      notification.success({
        message: `Statements for ${moment()
          .month(selectedMonth - 1)
          .format("MMMM")} ${selectedYear} generated successfully.`,
      });

      // ✅ Use the returned statements instead of triggering a refresh
      setFilteredStatements(response.data.statements);
    } catch (error) {
      console.error("Error generating statements:", error);
      notification.error({
        message: "Failed to generate statements. Please try again.",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  // Generate valid month options (Only allow past/current months for the current year)
  const monthOptions = moment.months().map((month, index) => ({
    value: index + 1, // Ensure 1-based month values
    label: month,
    disabled: selectedYear === currentYear && index + 1 > currentMonth, // Disable future months in the current year
  }));

  // Generate valid year options (Up to the current year)
  const yearOptions = Array.from({ length: currentYear - 1999 }, (_, i) => ({
    value: 2000 + i,
    label: `${2000 + i}`,
  }));

  // Filter statements when month or year changes
  useEffect(() => {
    const fetchStatements = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          `${config.API_URL}/statements/${selectedYear}/${selectedMonth}`
        );
        setFilteredStatements(response.data);
        setTotalStatements(response.data.length);
        setFilteredRowCount(response.data.length);
      } catch (error) {
        console.error("Error fetching statements:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedMonth && selectedYear) {
      fetchStatements();
    }
  }, [selectedMonth, selectedYear]);

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    columnWidth: 60,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_NONE,
      Table.SELECTION_INVERT,
      {
        key: "good-standing",
        text: "Select Good Standing",
        onSelect: () => {
          setSelectedRowKeys([
            ...filteredStatements
              .filter((c) => c.accountStatus === "GOOD_STANDING")
              .map((c) => c.id),
          ]);
        },
      },
      {
        key: "dnb-blue",
        text: "Select Past Due (Blue)",
        onSelect: () => {
          setSelectedRowKeys([
            ...filteredStatements
              .filter((c) => c.accountStatus === "PAST_DUE_BLUE")
              .map((c) => c.id),
          ]);
        },
      },
      {
        key: "dnb-red",
        text: "Select Past Due (Red)",
        onSelect: () => {
          setSelectedRowKeys([
            ...filteredStatements
              .filter((c) => c.accountStatus === "PAST_DUE_RED")
              .map((c) => c.id),
          ]);
        },
      },
      {
        key: "email",
        text: "Select Email Distribution",
        onSelect: () => {
          setSelectedRowKeys([
            ...filteredStatements
              .filter((c) => c.distributionCategory === "EMAIL")
              .map((c) => c.id),
          ]);
        },
      },
      {
        key: "fax",
        text: "Select Fax Distribution",
        onSelect: () => {
          setSelectedRowKeys([
            ...filteredStatements
              .filter((c) => c.distributionCategory === "FAX")
              .map((c) => c.id),
          ]);
        },
      },
      {
        key: "mail",
        text: "Select Mail Distribution",
        onSelect: () => {
          setSelectedRowKeys([
            ...filteredStatements
              .filter((c) => c.distributionCategory === "MAIL")
              .map((c) => c.id),
          ]);
        },
      },
      {
        key: "regular",
        text: "Select Rregular Balance",
        onSelect: () => {
          setSelectedRowKeys([
            ...filteredStatements.filter((c) => c.isRegular).map((c) => c.id),
          ]);
        },
      },
      {
        key: "irregular",
        text: "Select Irregular Balance",
        onSelect: () => {
          setSelectedRowKeys([
            ...filteredStatements.filter((c) => !c.isRegular).map((c) => c.id),
          ]);
        },
      },
    ],
  };

  // Open Drawer with Statement Details
  const handleViewDetails = (record) => {
    setSelectedStatement(record);
    setDrawerVisible(true);
  };

  // Close Drawer
  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  // Process Statements
  const handleProcessStatements = async (type) => {
    if (type === "selected" && selectedRowKeys.length === 0) {
      notification.warning({
        message: "No statements selected for processing",
      });
      return;
    }

    try {
      const statementIds =
        type === "all"
          ? filteredStatements.map((stmt) => stmt.id) // Process all
          : selectedRowKeys; // Process selected

      await axiosInstance.post(
        `${config.API_URL}/statements/update-distribution-status`,
        {
          statementIds,
        }
      );

      // Update UI immediately after processing
      setFilteredStatements((prev) =>
        prev.map((stmt) =>
          statementIds.includes(stmt.id)
            ? { ...stmt, distributionStatus: "distributed" }
            : stmt
        )
      );

      notification.success({
        message: `Marked ${
          type === "all" ? "all" : `${statementIds.length} selected`
        } statements as 'Distributed'.`,
      });

      // Clear selection after processing
      if (type === "selected") setSelectedRowKeys([]);
    } catch (error) {
      console.error("Error processing statements:", error);
      notification.error({
        message: "Failed to process statements. Please try again.",
      });
    }
  };

  // Download Statements
  const handleDownloadStatements = async (type) => {
    try {
      let url = `${config.API_URL}/statements/download`;

      if (type === "selected" && selectedRowKeys.length > 0) {
        url += `?statementIds=${selectedRowKeys.join(",")}`;
      } else if (type === "all") {
        url += `?month=${selectedMonth}&year=${selectedYear}`; // Send month and year for "Download All"
      }

      const response = await axiosInstance.get(url, {
        responseType: "blob", // Important for handling streamed file response
      });

      // Create a Blob from the response
      const blob = new Blob([response.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "statements.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      notification.success({
        message: `Downloaded ${
          type === "all" ? "all" : "selected"
        } statements.`,
      });
    } catch (error) {
      console.error("Error downloading statements:", error);
      notification.error({
        message: "Failed to download statements. Please try again.",
      });
    }
  };

  // Table Columns with Sorting & Filtering
  const columns = [
    {
      title: "Customer Name",
      dataIndex: ["customer", "name"],
      key: "customerName",
      sorter: (a, b) => a.customer.name.localeCompare(b.customer.name),
    },
    {
      title: "Account Status",
      dataIndex: "accountStatus",
      key: "accountStatus",
      filters: [
        { text: "Good Standing", value: "GOOD_STANDING" },
        { text: "Past Due (Blue)", value: "PAST_DUE_BLUE" },
        { text: "Past Due (Red)", value: "PAST_DUE_RED" },
      ],
      onFilter: (value, record) => record.accountStatus === value,
      render: (status) => {
        // Convert raw enum to friendly label
        switch (status) {
          case "GOOD_STANDING":
            return "Good Standing";
          case "PAST_DUE_BLUE":
            return "Past Due (Blue)";
          case "PAST_DUE_RED":
            return "Past Due (Red)";
          default:
            return status;
        }
      },
    },
    {
      title: "Distribution Category",
      dataIndex: "distributionCategory",
      key: "distributionCategory",
      filters: [
        { text: "Email", value: "EMAIL" },
        { text: "Fax", value: "FAX" },
        { text: "Mail", value: "MAIL" },
        { text: "International Mail", value: "INTERNATIONAL_MAIL" },
      ],
      onFilter: (value, record) => record.distributionCategory === value,
      render: (category) => {
        switch (category) {
          case "EMAIL":
            return "Email";
          case "FAX":
            return "Fax";
          case "MAIL":
            return "Mail";
          case "INTERNATIONAL_MAIL":
            return "International Mail";
          default:
            return category; // In case new categories are introduced
        }
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      sorter: (a, b) => a.balance - b.balance,
      render: (value) =>
        `$${value.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
    },
    {
      title: "Regular/Irregular",
      dataIndex: "isRegular",
      key: "isRegular",
      filters: [
        { text: "Regular", value: true },
        { text: "Irregular", value: false },
      ],
      onFilter: (value, record) => record.isRegular === value,
      render: (isRegular) => (isRegular ? "Regular" : "Irregular"),
    },
    {
      title: "Status",
      dataIndex: "distributionStatus", // ✅ Use correct attribute
      key: "distributionStatus",
      filters: [
        { text: "Pending", value: "pending" },
        { text: "Processing", value: "processing" },
        { text: "Distributed", value: "distributed" },
        { text: "Failed", value: "failed" },
      ],
      onFilter: (value, record) => record.distributionStatus === value,
      render: (status) => {
        switch (status) {
          case "pending":
            return "🕒 Pending"; // Gray icon for waiting
          case "processing":
            return "🔄 Processing"; // Spinning icon for in-progress
          case "distributed":
            return "✅ Distributed"; // Green checkmark for success
          case "failed":
            return "❌ Failed"; // Red cross for errors
          default:
            return status; // Fallback if new statuses are introduced
        }
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button
          type="default"
          onClick={() => handleViewDetails(record)}
          disabled={isGenerating}
        >
          View Details
        </Button>
      ),
    },
  ];

  return (
    <div class="flexDiv">
      {/* Statement Selection Section */}
      <Card title="Select Statement Period">
        <Row
          gutter={16}
          align="bottom"
          style={{ display: "flex", flexWrap: "nowrap" }}
        >
          <Col flex="1">
            <label>Month:</label>
            <Select
              style={{ width: "100%" }}
              value={selectedMonth}
              placeholder="Select Month"
              options={monthOptions}
              onChange={(value) => setSelectedMonth(value)}
              disabled={isGenerating}
            />
          </Col>
          <Col flex="1">
            <label>Year:</label>
            <Select
              style={{ width: "100%" }}
              value={selectedYear}
              placeholder="Select Year"
              options={yearOptions}
              onChange={(value) => setSelectedYear(value)}
              disabled={isGenerating}
            />
          </Col>
          <Col flex="0">
            <Button
              type="primary"
              loading={isGenerating}
              onClick={handleGenerateStatements}
              disabled={
                selectedYear > currentYear ||
                (selectedYear === currentYear && selectedMonth > currentMonth)
              }
            >
              Generate Statements
            </Button>
          </Col>
        </Row>
      </Card>

      {/* Statements Table Section */}
      <Card
        title="Statements"
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minHeight: 0, // Important to allow flex-based height adjustments
          overflow: "hidden",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minHeight: 0,
          }}
        >
          <Row style={{ marginBottom: 16 }}>
            <Col>
              <Button
                type="primary"
                onClick={() => handleProcessStatements("all")}
                disabled={isGenerating}
              >
                Process All
              </Button>
            </Col>
            <Col style={{ marginLeft: 8 }}>
              <Button
                type="primary"
                onClick={() => handleProcessStatements("selected")}
                disabled={isGenerating || selectedRowKeys.length === 0} // ✅ Disable if no rows selected
              >
                Process Selected
              </Button>
            </Col>
            <Col style={{ marginLeft: 8 }}>
              <Button
                type="primary"
                onClick={() => handleDownloadStatements("all")}
                disabled={isGenerating}
              >
                Download All
              </Button>
            </Col>
            <Col style={{ marginLeft: 8 }}>
              <Button
                type="primary"
                onClick={() => handleDownloadStatements("selected")}
                disabled={isGenerating || selectedRowKeys.length === 0} // ✅ Disable if no rows selected
              >
                Download Selected
              </Button>
            </Col>
            <Col style={{ marginLeft: 8 }}>
              <Button
                type="primary"
                danger
                onClick={handleDeleteStatements}
                disabled={selectedRowKeys.length === 0} // ✅ Disable if no rows selected
              >
                Delete Selected
              </Button>
            </Col>
            <Col
              style={{
                marginLeft: 20,
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                {selectedRowKeys.length > 0
                  ? `(${selectedRowKeys.length} selected) | `
                  : ""}
                {filteredRowCount} displayed | {totalStatements} total
              </span>
            </Col>
          </Row>
          <div style={{ flex: 1, overflow: "auto" }}>
            <Table
              rowSelection={rowSelection}
              dataSource={filteredStatements}
              columns={columns}
              rowKey="id"
              pagination={pagination}
              scroll={{ y: "calc(100vh - 500px)" }}
              onChange={handleTableChange}
              loading={isLoading}
              locale={{ emptyText: "No statements found" }}
            />
          </div>
        </div>
      </Card>

      {/* Drawer for Statement Details */}
      <Drawer
        title={`Statement Details - ${selectedStatement?.customer?.name || ""}`}
        placement="right"
        width={700}
        onClose={closeDrawer}
        open={drawerVisible}
      >
        <h3>Invoices & Transactions</h3>
        <Table
          dataSource={selectedStatement?.invoiceSnapshots || []}
          expandable={{
            expandedRowRender: (invoiceSnapshot) => {
              const transactions =
                invoiceSnapshot.invoiceTransactionSnapshots || [];

              if (transactions.length === 0) {
                return (
                  <p style={{ paddingLeft: 16 }}>
                    No transactions for this invoice.
                  </p>
                );
              }

              return (
                <Table
                  dataSource={transactions}
                  columns={[
                    {
                      title: "Transaction ID",
                      dataIndex: "transactionId",
                      key: "transactionId",
                    },
                    {
                      title: "Amount",
                      dataIndex: "amount",
                      key: "amount",
                      render: (value) => `$${Number(value).toFixed(2)}`,
                    },
                    {
                      title: "Date",
                      dataIndex: "date",
                      key: "date",
                      render: (value) => moment(value).format("YYYY-MM-DD"),
                    },
                    {
                      title: "Type",
                      dataIndex: "type",
                      key: "type",
                      render: (value) => value || "N/A",
                    },
                    { title: "Source", dataIndex: "source", key: "source" },
                    { title: "Comment", dataIndex: "comment", key: "comment" },
                  ]}
                  rowKey="id"
                  pagination={false}
                />
              );
            },
            rowExpandable: (invoiceSnapshot) =>
              invoiceSnapshot.invoiceTransactionSnapshots?.length > 0,
          }}
          columns={[
            {
              title: "Invoice Number",
              dataIndex: "invoiceNumber",
              key: "invoiceNumber",
            },
            {
              title: "Amount",
              dataIndex: "amount",
              key: "amount",
              render: (value) => `$${Number(value).toFixed(2)}`,
            },
            { title: "Due Date", dataIndex: "dueDate", key: "dueDate" },
            {
              title: "Balance",
              dataIndex: "balance",
              key: "balance",
              render: (value) => `$${Number(value).toFixed(2)}`,
            },
          ]}
          rowKey="id"
          pagination={false}
        />
      </Drawer>
    </div>
  );
};

export default StatementsScreen;
