import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  Spin,
  notification,
} from "antd";
import axiosInstance from "../axiosConfig";
import config from "../config";
import "./PendingActionModal.css";

const { TextArea } = Input;

Modal.setAppElement("#root");

const PendingActionModal = ({
  isVisible,
  onClose,
  initialValues = {},
  disableClientFields = false, // TODO Why do I need this?
  onSaveComplete, // Optional callback for parent component
}) => {
  const [form] = Form.useForm();
  const [employeeUsers, setEmployeeUsers] = useState([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (isVisible) {
      form.setFieldsValue(initialValues);

      const fetchEmployeeUsers = async () => {
        try {
          const response = await axiosInstance.get(
            `${config.API_URL}/employees/employee-users`
          );
          setEmployeeUsers(response.data);
        } catch (error) {
          console.error("Error fetching employee users:", error);
          notification.error({
            message: "Error",
            description: "Failed to fetch employee users.",
          });
        }
      };

      fetchEmployeeUsers();
    }
  }, [isVisible, initialValues, form]);

  const handleSave = async () => {
    try {
      setSaving(true);
      const values = await form.validateFields();
      const payload = {
        problem: values.problem,
        customerId: initialValues.customerId,
        customerName: values.customerName,
        invoiceId: values.invoiceId,
        invoiceNumber: values.invoiceNumber,
        visibleToClient: values.visibleToClient,
        disputeAmount: values.disputeAmount,
        assignedToId: values.assignedToId,
        nextFollowupAt: values.nextFollowupAt,
      };

      await axiosInstance.post(`${config.API_URL}/pending-actions`, payload);

      if (payload.assignedToId) {
        notification.success({
          message: "Pending action assigned successfully.",
        });
      } else {
        notification.success({
          message: "Pending action created successfully.",
        });
      }

      if (onSaveComplete) onSaveComplete();
      onClose();
    } catch (error) {
      console.error("Error saving pending action:", error);
      notification.error({
        message: "Error",
        description: "There was an error saving the pending action.",
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onClose}
      contentLabel="Create Pending Action"
      className="pending-action-modal"
      overlayClassName="pending-action-modal-overlay"
    >
      <div className="pending-action-modal-content">
        <div className="pending-action-modal-header">
          <h1>Create Pending Action</h1>
          <button className="pending-action-close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="pending-action-modal-body">
          <Spin spinning={saving}>
            <Form form={form} layout="vertical" className="pending-action-form">
              <Form.Item
                name="problem"
                label="Problem"
                rules={[
                  {
                    required: true,
                    message: "Problem description is required",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>

              <Form.Item
                name="customerName"
                label="Customer Name"
                rules={[
                  { required: true, message: "Customer name is required" },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>

              <Form.Item
                name="visibleToClient"
                label="Visible to Client"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>

              <Form.Item name="invoiceId" hidden>
                <Input type="hidden" />
              </Form.Item>

              <Form.Item
                name="invoiceNumber"
                label="Invoice Number"
                rules={[
                  { required: false, message: "Invoice number is required" },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>

              <Form.Item name="disputeAmount" label="Dispute Amount">
                <Input type="number" placeholder="0.00" />
              </Form.Item>

              <Form.Item name="assignedToId" label="Assigned To">
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {employeeUsers.map((user) => (
                    <Select.Option key={user.id} value={user.id}>
                      {`${user.firstName} ${user.lastName}`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="nextFollowupAt" label="Next Follow-up At">
                <Input type="datetime-local" />
              </Form.Item>
            </Form>
          </Spin>
        </div>
        <div className="pending-action-modal-footer">
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PendingActionModal;
