import React, { useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import './AddEmployee.css';

function AddEmployee() {
  const [formData, setFormData] = useState({
    name: '',
    position: '',
    department: '',
    hireDate: ''
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post(`${config.API_URL}/employees`, formData);
      const newEmployeeId = response.data.id;
      navigate(`/employees/${newEmployeeId}`);
    } catch (error) {
      console.error('Error adding employee:', error);
    }
  };

  return (
    <div className="flexDiv">
      <h2>Add Employee</h2>
      <form onSubmit={handleSubmit} className="add-employee-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Name" required />
        </div>
        <div className="form-group">
          <label htmlFor="position">Position</label>
          <input type="text" name="position" value={formData.position} onChange={handleChange} placeholder="Position" required />
        </div>
        <div className="form-group">
          <label htmlFor="department">Department</label>
          <input type="text" name="department" value={formData.department} onChange={handleChange} placeholder="Department" required />
        </div>
        <div className="form-group">
          <label htmlFor="hireDate">Hire Date</label>
          <input type="date" name="hireDate" value={formData.hireDate} onChange={handleChange} placeholder="Hire Date" required />
        </div>
        <button type="submit" className="submit-button">Add Employee</button>
      </form>
    </div>
  );
}

export default AddEmployee;
