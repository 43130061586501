import React, { useState } from "react";
import CustomerClients from "./CustomerClients";
import GenericSearch from "../GenericSearch";
import entitySearchConfig from "../../entitySearchConfig";
import axiosInstance from "../../axiosConfig";
import config from "../../config";
import Modal from "react-modal";
import { notification } from "antd";

function CustomerClientsTab({ formData, customerId }) {
  const [clients, setClients] = useState(formData || []);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);

  const handleClientSelect = (client) => {
    if (!client || !client.id) {
      setSelectedClient(null);
    } else {
      setSelectedClient(client);
    }
    setIsClientModalOpen(false); // Close modal after selecting a client
  };

  const addClientToCustomer = async () => {
    if (!selectedClient || !selectedClient.id) {
      console.warn("No client selected, skipping API call.");
      return;
    }
  
    if (!customerId) {
      console.error("No customerId provided, cannot add client.");
      return;
    }
  
    console.log(`Adding client ${selectedClient.id} to customer ${customerId}`);
  
    try {
      const response = await axiosInstance.post(
        `${config.API_URL}/customers/${customerId}/clients`,
        { clientId: selectedClient.id }
      );
  
      if (response.status === 200) {
        notification.warning({
          message: "Client Already Associated",
          description: `${selectedClient.name} is already linked to this customer.`,
        });
        return;
      }
  
      if (response.status === 201) {
        notification.success({
          message: "Client Added",
          description: `${selectedClient.name} was successfully linked to this customer.`,
        });
  
        // Update the clients list with the newly added client
        setClients((prevClients) => [...prevClients, selectedClient]);
  
        // Clear selection after adding
        setSelectedClient(null);
      }
    } catch (error) {
      console.error("Error adding client to customer:", error.response || error);
      notification.error({
        message: "Error Adding Client",
        description: "An error occurred while trying to associate the client.",
      });
    }
  };  

  return (
    <div>
      <h2>Customer Clients</h2>

      {/* Client Search and Add Button */}
      <div className="inline-search-container">
        <GenericSearch
          config={entitySearchConfig.client}
          onSelectItem={handleClientSelect}
          inlineSearch={true}
          selectedValue={selectedClient ? selectedClient.name : ""}
          autoFocus={true}
          openModal={() => setIsClientModalOpen(true)}
        />
        <button
          onClick={addClientToCustomer}
          disabled={!selectedClient}
          className="add-client-button"
        >
          Add Client
        </button>
      </div>

      {/* Client Search Modal */}
      <Modal
        isOpen={isClientModalOpen}
        onRequestClose={() => setIsClientModalOpen(false)}
        contentLabel="Client Search"
        className="search-modal"
        overlayClassName="search-modal-overlay"
      >
        <div className="search-content">
          <div className="search-header">
            <h2>Client Search</h2>
            <button onClick={() => setIsClientModalOpen(false)}>&times;</button>
          </div>
          <div className="search-body">
            <GenericSearch
              config={entitySearchConfig.client}
              onSelectItem={handleClientSelect}
              closeModal={() => setIsClientModalOpen(false)}
              inModal={true}
            />
          </div>
        </div>
      </Modal>

      {/* List of Associated Clients */}
      <CustomerClients clients={clients} />
    </div>
  );
}

export default CustomerClientsTab;