import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import axiosInstance from "../axiosConfig";
import config from "../config";
import { v4 as uuidv4 } from "uuid"; // Make sure to install uuid package
import "./JobManagement.css";
import ReactFlow, { MiniMap, Controls, Background } from "reactflow";
import dagre from "dagre";
import "reactflow/dist/style.css";
import { Table, Button, Card, Tooltip } from "antd";
import {
  DownloadOutlined,
  RedoOutlined,
  CheckCircleFilled,
  ClockCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  HourglassOutlined,
} from "@ant-design/icons";

const JobManagement = () => {
  const [fileType, setFileType] = useState("Customers");
  const [dependencies, setDependencies] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [workflowName, setWorkflowName] = useState("");
  const [workflowJobs, setWorkflowJobs] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState("");
  const [activeTab, setActiveTab] = useState("createWorkflow");
  const [workflowGraph, setWorkflowGraph] = useState({ nodes: [], edges: [] });
  const pollingIntervalRef = useRef(null);
  const [pageSize, setPageSize] = useState(10);

  const handleFileTypeChange = (e) => {
    setFileType(e.target.value);
  };

  const handleDependenciesChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setDependencies(selectedOptions);
  };

  const handleWorkflowNameChange = (e) => {
    setWorkflowName(e.target.value);
  };

  const handleAddWorkflowJob = () => {
    const newJob = {
      id: uuidv4(),
      jobType: fileType,
      sourceEntity: fileType,
      order: workflowJobs.length + 1,
      depends_on: dependencies,
    };
    setWorkflowJobs([newJob, ...workflowJobs]);
    setDependencies([]); // Reset dependencies
  };

  const handleCreateWorkflow = async () => {
    setIsSubmitting(true);

    try {
      await axiosInstance.post(`${config.API_URL}/workflows`, {
        name: workflowName,
        description: "",
        jobs: workflowJobs,
      });

      await fetchWorkflows();
    } catch (error) {
      console.error("Error creating workflow:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStartWorkflow = async () => {
    setIsSubmitting(true);

    try {
      await axiosInstance.post(
        `${config.API_URL}/workflows/${selectedWorkflow}/start`
      );
      fetchJobs();
    } catch (error) {
      console.error("Error starting workflow:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchJobs = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${config.API_URL}/jobs`);
      setJobs(response.data);

      const shouldPoll = response.data.some(
        (job) => job.status === "PENDING" || job.status === "IN_PROGRESS"
      );
      if (shouldPoll) {
        if (!pollingIntervalRef.current) {
          pollingIntervalRef.current = setInterval(fetchJobs, 10000);
        }
      } else {
        if (pollingIntervalRef.current) {
          clearInterval(pollingIntervalRef.current);
          pollingIntervalRef.current = null;
        }
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  }, []);

  const fetchWorkflows = async () => {
    try {
      const response = await axiosInstance.get(`${config.API_URL}/workflows`);
      setWorkflows(response.data);
    } catch (error) {
      console.error("Error fetching workflows:", error);
    }
  };

  const handleRerunJob = async (jobId) => {
    if (!window.confirm("Are you sure you want to rerun this job?")) return;

    try {
      await axiosInstance.post(`${config.API_URL}/jobs/${jobId}/rerun`);
      alert("Job has been requeued.");
      fetchJobs(); // Refresh job list
    } catch (error) {
      console.error("Error rerunning job:", error);
      alert("Failed to rerun job.");
    }
  };

  const downloadLog = async (jobId) => {
    try {
      const response = await axiosInstance.get(
        `${config.API_URL}/jobs/log/${jobId}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `job_log_${jobId}.txt`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading log:", error);
    }
  };

  useEffect(() => {
    fetchJobs();
    fetchWorkflows();

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [fetchJobs]);

  const dagreGraph = useMemo(() => {
    const graph = new dagre.graphlib.Graph();
    graph.setDefaultEdgeLabel(() => ({}));
    return graph;
  }, []); // No dependencies, so it will only be initialized once

  const nodeWidth = 250;
  const nodeHeight = 50;

  const getLayoutedElements = useCallback(
    (nodes, edges, direction = "TB") => {
      // const isHorizontal = direction === 'LR';
      dagreGraph.setGraph({ rankdir: direction });

      nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
      });

      edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
      });

      dagre.layout(dagreGraph);

      const layoutedNodes = nodes.map((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.position = {
          x: nodeWithPosition.x - nodeWidth / 2,
          y: nodeWithPosition.y - nodeHeight / 2,
        };
        return node;
      });

      return { nodes: layoutedNodes, edges };
    },
    [dagreGraph]
  );

  // Transform workflow jobs to nodes and edges, and then apply the layout
  const transformWorkflowToGraph = useCallback(
    (workflow) => {
      if (!workflow || !workflow.jobs) return { nodes: [], edges: [] };

      const nodes = [];
      const edges = [];

      workflow.jobs.forEach((job) => {
        // Update node's label to include targetEntity, sourceEntity, and migrationScript
        const nodeLabel = (
          <div>
            <div>
              <strong>Source:</strong> {job.sourceEntity}
            </div>
            <div>
              <strong>Target:</strong> {job.targetEntity}
            </div>
            <div>
              <strong>Script:</strong> {job.migrationScript}
            </div>
          </div>
        );

        nodes.push({
          id: job.id,
          data: { label: nodeLabel },
          position: { x: 0, y: 0 }, // Initial position; will be updated by dagre
          style: { width: 250 },
        });

        job.depends_on.forEach((dependency) => {
          edges.push({
            id: `e${dependency}-${job.id}`,
            source: dependency,
            target: job.id,
          });
        });
      });

      // Apply the layout
      return getLayoutedElements(nodes, edges);
    },
    [getLayoutedElements]
  );

  // Update the graph whenever a workflow is selected
  useEffect(() => {
    const selected = workflows.find(
      (workflow) => workflow.id === parseInt(selectedWorkflow, 10)
    );
    if (selected) {
      const graph = transformWorkflowToGraph(selected);
      console.log("Graph Nodes:", graph.nodes);
      console.log("Graph Edges:", graph.edges);
      setWorkflowGraph(graph);
    }
  }, [selectedWorkflow, workflows, transformWorkflowToGraph]);

  const columns = [
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      sorter: (a, b) => a.order - b.order,
      width: "auto",
      ellipsis: true,
    },
    {
      title: "Target",
      dataIndex: "target_entity",
      key: "target_entity",
      filters: [
        ...Array.from(new Set(jobs.map((job) => job.target_entity))).map(
          (value) => ({
            text: value,
            value,
          })
        ),
      ],
      onFilter: (value, record) => record.target_entity === value,
      width: "auto", // ✅ Adjusts dynamically
      ellipsis: true, // ✅ Prevents overflow
    },
    {
      title: "Source",
      dataIndex: "source_entity",
      key: "source_entity",
      width: 100,
      ellipsis: true,
      filters: [
        ...Array.from(new Set(jobs.map((job) => job.source_entity))).map(
          (value) => ({
            text: value,
            value,
          })
        ),
      ],      
      onFilter: (value, record) => record.source_entity === value,      
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: (
            <>
              <HourglassOutlined style={{ color: "green" }} /> READY
            </>
          ),
          value: "READY",
        },
        {
          text: (
            <>
              <ClockCircleOutlined style={{ color: "orange" }} /> PENDING
            </>
          ),
          value: "PENDING",
        },
        {
          text: (
            <>
              <SyncOutlined spin style={{ color: "blue" }} /> IN PROGRESS
            </>
          ),
          value: "IN_PROGRESS",
        },
        {
          text: (
            <>
              <CloseCircleOutlined style={{ color: "red" }} /> FAILED
            </>
          ),
          value: "FAILED",
        },
        {
          text: (
            <>
              <CheckCircleFilled style={{ color: "green" }} /> COMPLETED
            </>
          ),
          value: "COMPLETED",
        },
      ],
      onFilter: (value, record) => record.status === value,
      width: 80,
      align: "center",
      render: (status) => {
        const statusIcons = {
          READY: <HourglassOutlined style={{ color: "green" }} />,
          PENDING: <ClockCircleOutlined style={{ color: "orange" }} />,
          IN_PROGRESS: <SyncOutlined spin style={{ color: "blue" }} />,
          FAILED: <CloseCircleOutlined style={{ color: "red" }} />,
          COMPLETED: <CheckCircleFilled style={{ color: "green" }} />,
        };
        return (
          <Tooltip title={status}>
            {statusIcons[status] || status}{" "}
            {/* Fallback for unknown statuses */}
          </Tooltip>
        );
      },
    },
    {
      title: "Started At",
      dataIndex: "started_at",
      key: "started_at",
      sorter: (a, b) =>
        new Date(a.started_at || 0) - new Date(b.started_at || 0),
      render: (text) => (text ? new Date(text).toLocaleString() : "N/A"),
      width: 120,
    },
    {
      title: "Finished At",
      dataIndex: "finished_at",
      key: "finished_at",
      sorter: (a, b) =>
        new Date(a.finished_at || 0) - new Date(b.finished_at || 0),
      render: (text) => (text ? new Date(text).toLocaleString() : "N/A"),
      width: 120,      
    },
    {
      title: "Duration",
      key: "duration",
      witdh: "auto",
      sorter: (a, b) => {
        const durationA =
          a.started_at && a.finished_at
            ? new Date(a.finished_at) - new Date(a.started_at)
            : 0;
        const durationB =
          b.started_at && b.finished_at
            ? new Date(b.finished_at) - new Date(b.started_at)
            : 0;
        return durationA - durationB;
      },
      render: (_, record) => {
        return record.started_at && record.finished_at
          ? (new Date(record.finished_at) - new Date(record.started_at)) /
              1000 +
              "s"
          : "N/A";
      },
    },
    {
      title: "Records Read",
      dataIndex: "records_read",
      key: "records_read",
      witdh: "auto",
      sorter: (a, b) => (a.records_read || 0) - (b.records_read || 0),
      render: (text) => text || "N/A",
    },
    {
      title: "Records Written",
      dataIndex: "records_written",
      key: "records_written",
      witdh: "auto",
      sorter: (a, b) => (a.records_written || 0) - (b.records_written || 0),
      render: (text) => text || "N/A",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      witdh: "auto",
      render: (text) => text || "N/A",
    },
    {
      title: "Actions",
      key: "actions",
      width: "auto", // ✅ Ensures buttons take minimal space
      render: (_, record) => (
        <>
          <Tooltip title="Download Log">
            <Button
              icon={<DownloadOutlined />}
              onClick={() => downloadLog(record.job_id)}
              style={{ marginRight: 8 }}
            />
          </Tooltip>
          <Tooltip title="Rerun Job">
            <Button
              icon={<RedoOutlined />}
              onClick={() => handleRerunJob(record.job_id)}
              disabled={["READY", "PENDING", "IN_PROGRESS"].includes(record.status)}
              type="primary"
            />
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <div className="flexDiv">
      <h2>Job Management</h2>

      <div className="tabs">
        <button
          className={`tab ${activeTab === "createWorkflow" ? "active" : ""}`}
          onClick={() => setActiveTab("createWorkflow")}
        >
          Create Workflow
        </button>
        <button
          className={`tab ${activeTab === "startWorkflow" ? "active" : ""}`}
          onClick={() => setActiveTab("startWorkflow")}
        >
          Start Workflow
        </button>
        <button
          className={`tab ${activeTab === "jobList" ? "active" : ""}`}
          onClick={() => setActiveTab("jobList")}
        >
          Job List
        </button>
      </div>

      {activeTab === "createWorkflow" ? (
        <div className="tab-content">
          <form>
            <div>
              <label>Workflow Name:</label>
              <input
                type="text"
                value={workflowName}
                onChange={handleWorkflowNameChange}
                placeholder="Workflow Name"
              />
            </div>
            <div>
              <label>Target Entity:</label>
              <select value={fileType} onChange={handleFileTypeChange}>
                <option value="Accounts">Accounts</option>
                <option value="Customers">Customers</option>
                <option value="Clients">Clients</option>
                <option value="Suppliers">Suppliers</option>
                <option value="ClientCustomers">Client Customers</option>
                <option value="PurchaseOrders">Purchase Orders</option>
                <option value="Schedules">Schedules</option>
                <option value="Invoices">Invoices</option>
                <option value="InvoiceTransactions">
                  Invoice Transactions
                </option>
                <option value="Journal">Journal</option>
                <option value="CollectionReports">Collection Reports</option>
                <option value="CollectionReportInvoices">
                  Collection Report Invoices
                </option>
                <option value="AccountsPayable">Accounts Payable</option>
              </select>
            </div>
            <div>
              <label>Dependencies:</label>
              <select
                multiple
                value={dependencies}
                onChange={handleDependenciesChange}
              >
                {workflowJobs.map((job, index) => (
                  <option key={index} value={job.id}>
                    {job.jobType}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <button type="button" onClick={handleAddWorkflowJob}>
                Add to Workflow
              </button>
              <button
                type="button"
                onClick={handleCreateWorkflow}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Creating Workflow..." : "Create Workflow"}
              </button>
            </div>
          </form>

          <div className="collapsible">
            <button className="collapsible-header">Workflow Jobs</button>
            <div className="collapsible-content">
              <ul>
                {workflowJobs.map((job, index) => (
                  <li key={index}>
                    {job.jobType} (Order: {job.order}) - Source Entity:{" "}
                    {job.sourceEntity}
                    <div>
                      <label>Depends On:</label>
                      <ul>
                        {job.depends_on.map((dep) => (
                          <li key={dep}>
                            {workflowJobs.find((j) => j.id === dep)?.jobType ||
                              dep}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ) : null}

      {activeTab === "startWorkflow" ? (
        <div className="tab-content">
          <h3>Workflows</h3>
          <select
            value={selectedWorkflow}
            onChange={(e) => setSelectedWorkflow(e.target.value)}
          >
            <option value="">Select a Workflow</option>
            {workflows.map((workflow) => (
              <option key={workflow.id} value={workflow.id}>
                {workflow.name}
              </option>
            ))}
          </select>
          <button
            onClick={handleStartWorkflow}
            disabled={isSubmitting || !selectedWorkflow}
          >
            {isSubmitting ? "Starting Workflow..." : "Start Workflow"}
          </button>

          {selectedWorkflow && workflowGraph.nodes.length > 0 ? (
            <div className="graph-container">
              <ReactFlow
                nodes={workflowGraph.nodes}
                edges={workflowGraph.edges}
                fitView
              >
                <MiniMap />
                <Controls />
                <Background />
              </ReactFlow>
            </div>
          ) : null}
        </div>
      ) : null}

      {activeTab === "jobList" && (
        <Card
          title="Jobs"
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minHeight: 0,
            overflow: "hidden",
          }}
        >
          <div style={{ flex: 1, overflow: "auto" }}>
            <Table
              columns={columns}
              dataSource={jobs.map((job) => ({ ...job, key: job.job_id }))}
              rowKey="job_id"
              pagination={{
                pageSize: pageSize,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                onShowSizeChange: (_, newSize) => setPageSize(newSize),
              }}
              scroll={{ x: "max-content", y: "calc(100vh - 425px)" }}
            />
          </div>
        </Card>
      )}
    </div>
  );
};

export default JobManagement;
