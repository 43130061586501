import React, { useState } from 'react';
import axiosInstance from '../axiosConfig';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import FactoringRateComponent from './FactoringRateComponent';
import PaymentOptionForm from './PaymentOptionForm';
import './AddClient.css';

function AddClient() {
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    fax: '',
    phone: '',
    advanceRate: '',
    termsOfContract: '',
    paymentHold: '',
    factoringRates: { type: 'flat', rates: { flatRate: '', additionalRate: '' } },
    contacts: [{ name: '', phoneNumber: '', email: '' }],
    paymentOptions: [],
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleContactChange = (e, index) => {
    const { name, value } = e.target;
    const newContacts = formData.contacts.map((contact, i) => (
      i === index ? { ...contact, [name]: value } : contact
    ));
    setFormData({
      ...formData,
      contacts: newContacts
    });
  };

  const handleFactoringRateChange = (rateData) => {
    setFormData({
      ...formData,
      factoringRates: rateData
    });
  };

  const handlePaymentOptionChange = (index, paymentOption) => {
    const newPaymentOptions = formData.paymentOptions.map((option, i) => (
      i === index ? paymentOption : option
    ));
    setFormData({
      ...formData,
      paymentOptions: newPaymentOptions
    });
  };

  const addContact = () => {
    setFormData({
      ...formData,
      contacts: [...formData.contacts, { name: '', phoneNumber: '', email: '' }]
    });
  };

  const removeContact = (index) => {
    const newContacts = formData.contacts.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      contacts: newContacts
    });
  };

  const addPaymentOption = () => {
    setFormData({
      ...formData,
      paymentOptions: [...formData.paymentOptions, { type: 'Check', preferenceRank: formData.paymentOptions.length + 1 }]
    });
  };

  const removePaymentOption = (index) => {
    const newPaymentOptions = formData.paymentOptions.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      paymentOptions: newPaymentOptions
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const clientData = {
      ...formData,
      paymentHold: formData.paymentHold ? parseInt(formData.paymentHold, 10) : 0,
      advanceRate: formData.advanceRate ? parseFloat(formData.advanceRate) / 100 : null,
      flatFactoringRate: formData.factoringRates.type === 'flat' && formData.factoringRates.rates.flatRate ? parseFloat(formData.factoringRates.rates.flatRate) / 100 : null,
      additionalRate: formData.factoringRates.type === 'flat' && formData.factoringRates.rates.additionalRate ? parseFloat(formData.factoringRates.rates.additionalRate) / 100 : null,
      slidingScaleRates: formData.factoringRates.type === 'sliding' ? formData.factoringRates.rates : null
    };
    try {
      const response = await axiosInstance.post(`${config.API_URL}/clients`, clientData);
      const newClientId = response.data.id;
      navigate(`/clients/${newClientId}`);
    } catch (error) {
      console.error('Error adding client:', error);
    }
  };

  return (
    <div className="flexDiv">
      <h2>Add Client</h2>
      <form onSubmit={handleSubmit} className="add-client-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Name" required />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address</label>
          <input type="text" name="address" value={formData.address} onChange={handleChange} placeholder="Address" required />
        </div>
        <div className="form-group">
          <label htmlFor="fax">Fax</label>
          <input type="text" name="fax" value={formData.fax} onChange={handleChange} placeholder="Fax" />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input type="text" name="phone" value={formData.phone} onChange={handleChange} placeholder="Phone" />
        </div>
        <div className="form-group">
          <label htmlFor="advanceRate">Advance Rate (%)</label>
          <input type="number" name="advanceRate" value={formData.advanceRate} onChange={handleChange} placeholder="Advance Rate" required />
        </div>
        <div className="form-group">
          <label htmlFor="termsOfContract">Terms of the Contract</label>
          <textarea name="termsOfContract" value={formData.termsOfContract} onChange={handleChange} placeholder="Terms of the Contract" />
        </div>
        <div className="form-group">
          <label htmlFor="paymentHold">Payment Hold (days)</label>
          <input
            type="number"
            name="paymentHold"
            value={formData.paymentHold}
            onChange={handleChange}
            placeholder="0"
            min="0"
          />
        </div>

        <FactoringRateComponent onRateChange={handleFactoringRateChange} initialRateData={formData.factoringRates} />

        <h3>Contacts</h3>
        {formData.contacts.map((contact, index) => (
          <div key={index} className="contact-group">
            <div className="form-group">
              <label htmlFor={`contact-name-${index}`}>Contact Name</label>
              <input type="text" name="name" value={contact.name} onChange={(e) => handleContactChange(e, index)} placeholder="Contact Name" required />
            </div>
            <div className="form-group">
              <label htmlFor={`contact-phoneNumber-${index}`}>Phone Number</label>
              <input type="text" name="phoneNumber" value={contact.phoneNumber} onChange={(e) => handleContactChange(e, index)} placeholder="Phone Number" required />
            </div>
            <div className="form-group">
              <label htmlFor={`contact-email-${index}`}>Email</label>
              <input type="email" name="email" value={contact.email} onChange={(e) => handleContactChange(e, index)} placeholder="Email" required />
            </div>
            <button type="button" onClick={() => removeContact(index)} className="remove-contact-button">Remove Contact</button>
          </div>
        ))}
        <button type="button" onClick={addContact} className="add-contact-button">Add Another Contact</button>

        <h3>Payment Options</h3>
        {formData.paymentOptions.map((paymentOption, index) => (
          <PaymentOptionForm
            key={index}
            index={index}
            paymentOption={paymentOption}
            onChange={handlePaymentOptionChange}
            onRemove={removePaymentOption}
          />
        ))}
        <button type="button" onClick={addPaymentOption} className="add-contact-button">Add Payment Option</button>

        <button type="submit" className="submit-button">Add Client</button>
      </form>
    </div>
  );
}

export default AddClient;
