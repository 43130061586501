import React, { useState } from "react";
import Modal from "react-modal";
import GenericSearch from "./GenericSearch";
// import "./SearchWithModalWrapper.css"; // Optional styling overrides

Modal.setAppElement("#root");

export default function GenericSearchWithModalWrapper({
  config,
  selectedItem,
  onSelectItem,
  label = "Search",
  inline = true,
  style = {},
  className = "",
  disabled = false,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {inline && (
        <div className={className}>
          <span style={{ marginRight: 8 }}>{label}:</span>
          <GenericSearch
            config={config}
            onSelectItem={onSelectItem}
            inlineSearch={true}
            selectedValue={selectedItem?.name}
            openModal={() => setIsModalOpen(true)}
            style={style}
            disabled={disabled}
          />
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel={`${label} Modal`}
        className="search-modal"
        overlayClassName="search-modal-overlay"
      >
        <div className="search-content">
          <div className="search-header">
            <h2>{label}</h2>
            <button
              className="close-button"
              onClick={() => setIsModalOpen(false)}
            >
              &times;
            </button>
          </div>
          <div className="search-body">
            <GenericSearch
              config={config}
              onSelectItem={(item) => {
                onSelectItem(item);
                setIsModalOpen(false);
              }}
              inModal={true}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
