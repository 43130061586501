import React, { useState } from "react";
import Modal from "react-modal";
import axiosInstance from "../axiosConfig";
import config from "../config";
import { useNavigate } from "react-router-dom";
import "./AddClient.css"; // Import the same CSS file
import GenericSearch from "./GenericSearch";
import entitySearchConfig from "../entitySearchConfig";

function AddCustomer() {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    email: "",
    phone: "",
    fax: "",
    emailStatements: false,
    faxStatements: false,
    creditLimit: "",
    creditHistory: "",
    collectionsStatus: "",
    riskRating: "",
    daysPayOnAvg: "",
    clientId: "",
    contacts: [{ name: "", phoneNumber: "", email: "" }],
  });
  const [selectedClient, setSelectedClient] = useState(null);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleContactChange = (e, index) => {
    const { name, value } = e.target;
    const newContacts = formData.contacts.map((contact, i) =>
      i === index ? { ...contact, [name]: value } : contact
    );
    setFormData({
      ...formData,
      contacts: newContacts,
    });
  };

  const addContact = () => {
    setFormData({
      ...formData,
      contacts: [
        ...formData.contacts,
        { name: "", phoneNumber: "", email: "" },
      ],
    });
  };

  const removeContact = (index) => {
    const newContacts = formData.contacts.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      contacts: newContacts,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const sanitizedData = {
        ...formData,
        clientId: formData.clientId || null, // Ensure clientId is included
        creditLimit: formData.creditLimit !== "" ? formData.creditLimit : null,
        riskRating: formData.riskRating !== "" ? formData.riskRating : null,
        daysPayOnAvg:
          formData.daysPayOnAvg !== "" ? formData.daysPayOnAvg : null,
      };

      const response = await axiosInstance.post(
        `${config.API_URL}/customers`,
        sanitizedData
      );

      navigate(`/customers/${response.data.id}`);
    } catch (error) {
      console.error("Error adding customer:", error);
    }
  };

  const handleClientSelect = (client) => {
    const isClientCleared = !client.id || !client.name;

    if (isClientCleared) {
      setSelectedClient(null);
      setFormData((prevData) => ({
        ...prevData,
        clientId: "", // Ensure clientId is cleared
      }));
    } else {
      setSelectedClient(client);
      setFormData((prevData) => ({
        ...prevData,
        clientId: client.id, // Store clientId in formData
      }));
    }

    setIsClientModalOpen(false);
  };

  const openClientModal = () => {
    setIsClientModalOpen(true);
  };

  return (
    <div className="flexDiv">
      <h2>Add Customer</h2>
      <form onSubmit={handleSubmit} className="add-client-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            placeholder="Address"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Phone"
          />
        </div>
        <div className="form-group">
          <label htmlFor="fax">Fax</label>
          <input
            type="text"
            name="fax"
            value={formData.fax}
            onChange={handleChange}
            placeholder="Fax"
          />
        </div>
        <div className="form-group">
          <label>
            Email Statements:
            <input
              type="checkbox"
              name="emailStatements"
              checked={formData.emailStatements}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="form-group">
          <label>
            Fax Statements:
            <input
              type="checkbox"
              name="faxStatements"
              checked={formData.faxStatements}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="creditLimit">Credit Limit</label>
          <input
            type="number"
            name="creditLimit"
            value={formData.creditLimit}
            onChange={handleChange}
            placeholder="Credit Limit"
          />
        </div>
        <div className="form-group">
          <label htmlFor="creditHistory">Credit History</label>
          <textarea
            name="creditHistory"
            value={formData.creditHistory}
            onChange={handleChange}
            placeholder="Credit History"
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="collectionsStatus">Collections Status</label>
          <input
            type="text"
            name="collectionsStatus"
            value={formData.collectionsStatus}
            onChange={handleChange}
            placeholder="Collections Status"
          />
        </div>
        <div className="form-group">
          <label htmlFor="riskRating">Risk Rating</label>
          <input
            type="number"
            name="riskRating"
            value={formData.riskRating}
            onChange={handleChange}
            placeholder="Risk Rating"
          />
        </div>
        <div className="form-group">
          <label htmlFor="daysPayOnAvg"># Days Pay on Avg</label>
          <input
            type="number"
            name="daysPayOnAvg"
            value={formData.daysPayOnAvg}
            onChange={handleChange}
            placeholder="# Days Pay on Avg"
          />
        </div>
        <div className="form-group">
          <label>Client</label>
          <div className="inline-search-container">
            <GenericSearch
              config={entitySearchConfig.client}
              onSelectItem={handleClientSelect}
              inlineSearch={true}
              selectedValue={selectedClient ? selectedClient.name : ""}
              autoFocus={true}
              openModal={openClientModal}
            />
          </div>
        </div>

        <h3>Contacts</h3>
        {formData.contacts.map((contact, index) => (
          <div key={index} className="contact-group">
            <div className="form-group">
              <label htmlFor={`contact-name-${index}`}>Contact Name</label>
              <input
                type="text"
                name="name"
                value={contact.name}
                onChange={(e) => handleContactChange(e, index)}
                placeholder="Contact Name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor={`contact-phoneNumber-${index}`}>
                Phone Number
              </label>
              <input
                type="text"
                name="phoneNumber"
                value={contact.phoneNumber}
                onChange={(e) => handleContactChange(e, index)}
                placeholder="Phone Number"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor={`contact-email-${index}`}>Email</label>
              <input
                type="email"
                name="email"
                value={contact.email}
                onChange={(e) => handleContactChange(e, index)}
                placeholder="Email"
                required
              />
            </div>
            <button
              type="button"
              onClick={() => removeContact(index)}
              className="remove-contact-button"
            >
              Remove Contact
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={addContact}
          className="add-contact-button"
        >
          Add Another Contact
        </button>

        <button type="submit" className="submit-button">
          Add Customer
        </button>
      </form>
      <Modal
        isOpen={isClientModalOpen}
        onRequestClose={() => setIsClientModalOpen(false)}
        contentLabel="Client Search"
        className="search-modal"
        overlayClassName="search-modal-overlay"
      >
        <div className="search-content">
          <div className="search-header">
            <h2>Client Search</h2>
            <button onClick={() => setIsClientModalOpen(false)}>&times;</button>
          </div>
          <div className="search-body">
            <GenericSearch
              config={entitySearchConfig.client}
              onSelectItem={handleClientSelect}
              closeModal={() => setIsClientModalOpen(false)}
              inModal={true}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddCustomer;
